var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
import { useJourneyStore } from '.';
import factory from '../services/brick-factory';
import publish from '../services/publish';
import { JourneyStatus } from '../../app/api-generated/enums';
import _ from 'lodash';
import api from '../../app/api-generated/journey';
import { cache } from '@dnx/core';
export const EditorModes = {
    select: 1,
    add: 2,
    nav: 3,
};
export const useComposerStore = defineStore('composer', {
    state: () => ({
        factory,
        journey: useJourneyStore(),
        definitions: factory.definition,
        selection: [],
        mode: EditorModes.nav,
        devtools: false,
        canvas: 0,
        picked: {},
        previousRevisionData: undefined,
        listJourneyRevisions: [],
    }),
    getters: {
        selectedBrick: state => (state.selection.length === 0 ? undefined : state.journey.bricks[state.selection[0]]),
        identicalToPreviousRevision: state => {
            if (!state.previousRevisionData)
                return false;
            const comparedFields = ['bricks', 'filters', 'groups', 'connectedJourneys', 'name'];
            const journeyStore = useJourneyStore();
            const currentRevisionData = publish.serialize(journeyStore);
            //Recursively removes any empty objects
            function clean(el) {
                function internalClean(el) {
                    return _.transform(el, function (result, value, key) {
                        const isCollection = _.isObject(value);
                        const cleaned = isCollection ? internalClean(value) : value;
                        if (isCollection && _.isEmpty(cleaned)) {
                            return;
                        }
                        _.isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
                    });
                }
                return _.isObject(el) ? internalClean(el) : el;
            }
            const isIdentical = _.isEqual(clean(_(state.previousRevisionData).pick(comparedFields).value()), clean(_(currentRevisionData).pick(comparedFields).value()));
            return isIdentical;
        },
    },
    actions: {
        removeBrick(id) {
            if (!id || !this.journey.bricks[id]) {
                return;
            }
            this.selection = [...this.selection.filter(item => item !== id)];
            this.picked = {};
            const connections = this.journey.bricks[id].connections;
            for (const connid in connections) {
                this.journey.deleteConnection(connid);
            }
            delete this.journey.bricks[id];
            this.canvas++;
        },
        clear() {
            this.selection = [];
            this.picked = {};
            this.canvas++;
            this.journey.$reset();
        },
        publish() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield publish.publish();
            });
        },
        save() {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield publish.save();
                //Clear journey list cache
                if (response.success)
                    yield cache.delete('journey.list');
                this.loadPreviousRevisionData();
                return response;
            });
        },
        deactivate() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield publish.deactivate();
            });
        },
        delete() {
            return __awaiter(this, void 0, void 0, function* () {
                if (this.journey.isNew) {
                    this.clear();
                    return { success: true };
                }
                return yield publish.delete();
            });
        },
        loadPreviousRevisionData() {
            return __awaiter(this, void 0, void 0, function* () {
                const listResponse = yield api.list();
                const journeys = listResponse.value;
                const previousRevisions = journeys
                    .filter(journey => journey.id == this.journey.id &&
                    journey.status != JourneyStatus.Draft &&
                    journey.revisionId != this.journey.revisionId)
                    .sort((journeyA, journeyB) => {
                    return journeyA.revisionId < journeyB.revisionId ? 1 : -1;
                });
                if (!previousRevisions.length)
                    return;
                this.listJourneyRevisions = previousRevisions;
                const previousRevision = previousRevisions[0];
                const previousRevisionResponse = yield api.getById(previousRevision.id, previousRevision.revisionId);
                this.previousRevisionData = previousRevisionResponse.value;
            });
        },
    },
});
