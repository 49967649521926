// The publishservice publishes a journey.

import {useJourneyStore} from '../store';
import {isEmpty} from '../util';
import api from '../../app/api-generated/journey';
import nonProdApi from '../../app/api-generated/nonProdJourney'
import {Uuid} from '@dnx/core';
import datamodel from './datamodel';
import eventBus, {Events} from './event-bus';
import {T} from '@dnx/core';

import {Definitions as checkpointDefinitions, Types} from '../types/checkpoint-definition';
import brickTypes from '../types/brick-types';
import {cache} from '@dnx/core';

const audienceType = {
  new: 1,
  existing: 2,
};

function clean(brick) {
  const keep = ['id', 'type', 'name', 'x', 'y', 'connections', 'config', 'journeyId', 'remark'];
  for (const name in brick) {
    if (!keep.includes(name)) {
      delete brick[name];
    }
  }
}

function cleanConfig(brick) {
  if (brick.type === brickTypes.goal) {
    if (!brick.config.setupAudience) {
      delete brick.config.audienceSettings;
    } else {
      if (brick.config.audienceSettings.audienceType !== audienceType.new) {
        delete brick.config.audienceSettings.newAudienceName;
      }
      if (brick.config.audienceSettings.audienceType !== audienceType.existing) {
        delete brick.config.audienceSettings.existingAudience;
      }
    }
  }
}

export class PublishService {
  serialize(journey, comments = {}) {
    try {
      // when a store in passed in, take the raw state instead of the entire store instance
      // taking the entire store instance may have unwanted side-effects due to all the extra pinia/vue stuff present on it
      journey = journey.$state || journey;

      const clone = JSON.parse(
        JSON.stringify(journey, (key, value) => (key && key.startsWith('_') ? undefined : value))
      );

      delete clone.review;

      if (!clone.revisionId) {
        // stringify removes it.
        clone.revisionId = undefined;
      }
      if (isEmpty(clone.id)) {
        clone.id = Uuid.NewUuid().toString();
      }

      clone.filters = clone.groups;
      delete clone.groups;

      clone.config = JSON.stringify(clone.config);
      clone.brickComments = Object.keys(comments).map(id => ({
        brickId: id,
        content: comments[id],
      }));

      //Backend expects connections/bricks as arrays
      clone.bricks = Object.values(clone.bricks);

      // Adjust bricks
      clone.bricks.forEach(brick => {
        // Duplicate id into brick
        brick.journeyId = clone.id;
        // Only outgoing connections
        brick.connections = Object.values(brick.connections).filter(c => !brick.incoming[c.id]);
        // Absolute position
        brick.x = Math.round(brick.x);
        brick.y = Math.round(brick.y);

        cleanConfig(brick);
        brick.config = JSON.stringify(brick.config);
        clean(brick);
      });

      clone.bricks.forEach(brick => {
        brick.connections.forEach(connection => {
          // Update checkpoints for save in db
          const updateCheckpoint = name => {
            const isActive = !isEmpty(connection.checkpoints[name]);
            const hasExclusion = !isEmpty(connection.checkpoints[Types.exclusionjourney]);
            const allFiltersExcluded =
              hasExclusion &&
              connection.checkpoints[Types.exclusionjourney].excludedFilters?.length === clone?.filters?.length;
            const addDedupBase =
              name === Types.audiencededuplication && clone.filters && clone.filters.length && !allFiltersExcluded;
            const includeInOrder = name !== Types.exclusionjourney && (isActive || addDedupBase);

            if (includeInOrder) {
              checkpointOrder.push(name);
            }
            if (isActive) {
              connection.config[name] = checkpointDefinitions[name].update(connection.checkpoints[name]);
            } else {
              delete connection.config[name]; //Forcefully remove when inactive
            }
          };

          const checkpointOrder = [];

          updateCheckpoint(Types.targetdeduplication); //1
          updateCheckpoint(Types.audiencededuplication); //2
          updateCheckpoint(Types.delay); //3
          updateCheckpoint(Types.filter); //4
          updateCheckpoint(Types.timer); //5
          updateCheckpoint(Types.verification); //6
          updateCheckpoint(Types.exclusionjourney); //7

          //Backend requires order to contain at least a single entry
          //an empty order causes the backend to implode
          if (checkpointOrder.length) {
            connection.config.order = checkpointOrder;
          } else {
            delete connection.config.order;
          }

          connection.config = JSON.stringify(connection.config);
        });
      });

      return clone;
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  /** Refresh journey from server */
  async refresh(revisionId = undefined) {
    const journey = useJourneyStore();
    if (isEmpty(journey?.id)) {
      return;
    }

    // const current = cache.has(
    //   `journey.${journey.id}.${journey.revisionId}.getById`
    // )
    //   ? cache.getFromApi(`journey.${journey.id}.${journey.revisionId}.getById`)
    //   : undefined;
    const updated = await api.getById(journey.id, revisionId);
    const json = updated.success ? updated.value : undefined;
    if (!json) {
      console.error('Could not get update');
      return;
    }

    const latest = datamodel.unpack(json);
    journey.revisionId = latest.revisionId;
    journey.state = latest.state;

    if (!cache.update(`journey.${journey.id}.${journey.revisionId}.getById`, json)) {
      return;
    }
    // If we can determine local changes, we can reforce reload. For now force reload.
    // Also starts a session
    await journey.load(journey.id, journey.revisionId);
  }

  /**
   * Attempt to save the journey in its current form
   *
   * journey is not required to be valid
   */
  async save() {
    const status = {
      success: false,
      reasons: ['Unknown'],
    };

    try {
      const journey = useJourneyStore();

      const payload = this.serialize(journey);

      if (isEmpty(payload)) {
        status.reasons[0] = T('COMPOSER_CONFIRM_CANNOT_SAVE');
      } else {
        const response = journey.isNew ? await api.create(payload) : await api.update(payload);

        status.success = response.success;
        status.reasons[0] = response.value?.message ?? response.message;
        journey.isNew = false;

        if (response.success) {
          await this.refresh();
          eventBus.emit(Events.JOURNEY_UPDATED);
        }
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return status;
    }
  }

  /**
   * Publish a reviewed journey, journey is required to be reviewed and is assumed valid (may change if editing during review is to become allowed)
   *
   * Once published, the journeys state for its current revision is frozen and won't be editable anymore
   */
  async publish() {
    const journey = useJourneyStore();

    //Journey not reviewed yet?
    if (!journey.isPublishable)
      return {
        success: false,
        reasons: [T('JOURNEY_COMPOSER_PUBLISH_NOT_ALLOWED')],
      };

    //Disable publishing for a second time
    journey.isPublishable = false;

    try {
      const response = await api.publish(journey.id);

      if (!response.success) {
        journey.isPublishable = true;
        return {
          success: false,
          reasons: [response.value?.message ?? response.message],
        };
      }

      eventBus.emit(Events.JOURNEY_UPDATED);
      await this.refresh(journey.revisionId);

      return {success: true, reasons: []};
    } catch (e) {
      //We failed to publish, allow user to retry
      journey.isPublishable = true;

      return {success: false, reasons: [T('JOURNEY_COMPOSER_PUBLISH_FAILED')]};
    }
  }

  /**
   * Sends a journey to to be published on a production site
   * 
   */
  async sendToProduction() {
    const journey = useJourneyStore();

    //Journey not reviewed yet?
    if (!journey.isPublishable)
      return {
        success: false,
        reasons: [T('JOURNEY_COMPOSER_PUBLISH_NOT_ALLOWED')],
      };

    //Disable publishing for a second time
    journey.isPublishable = false;

    try {
      const model = this.serialize(journey);
      const response = await nonProdApi.post(model);

      if (!response.success) {
        journey.isPublishable = true;
        return {
          success: false,
          reasons: [response.value?.message ?? response.message],
        };
      }

      eventBus.emit(Events.JOURNEY_UPDATED);
      await this.refresh(journey.revisionId);

      return {success: true, reasons: []};
    } catch (e) {
      //We failed to publish, allow user to retry
      journey.isPublishable = true;

      return {success: false, reasons: [T('JOURNEY_COMPOSER_PUBLISH_FAILED')]};
    }
  }

  /**
   * Deactivate a published/live journey, this will prevent any further records from flowing through the journey
   */
  async deactivate() {
    const journey = useJourneyStore();
    if (!journey.canDeactivate) return {success: false, reasons: [T('JOURNEY_COMPOSER_DEACTIVATION_NOT_ALLOWED')]};

    try {
      journey.isActive = false; //Mimic

      const response = await api.deactivate(journey.id, journey.revisionId);
      if (!response.success) return {success: false, reasons: [response.value?.message ?? response.message]};

      eventBus.emit(Events.JOURNEY_UPDATED);
      await this.refresh(journey.revisionId); //Explicitly pass revisionid to prevent hydrating with latest

      return {success: true, reasons: []};
    } catch (e) {
      journey.isActive = true; //Reset

      return {success: false, reasons: [T('JOURNEY_COMPOSER_DEACTIVATION_FAILED')]};
    }
  }

  async delete() {
    const journey = useJourneyStore();
    if (isEmpty(journey?.id)) {
      return {success: true};
    }
    if (!journey.canDelete) {
      return {
        success: false,
        reason: T('JOURNEY_SETTINGS_DELETING_FAILED'),
      };
    }
    if (Object.values(journey.bricks).some(b => b.type === brickTypes.journey)) {
      return {
        success: false,
        level: 'error',
        reason: T('JOURNEY_SETTINGS_DELETING_JOURNEY'),
      };
    }

    const response = await api.delete(journey.id);

    if (response.success) {
      eventBus.emit(Events.JOURNEY_UPDATED);
    }

    if (response.statusCode === 403 && response.message === 'Forbidden') {
      response.message = T('JOURNEY_SETTINGS_DELETING_FAILED');
    }

    return {
      success: response.success,
      reason: response.success || isEmpty(response.message) ? undefined : response.message,
    };
  }
}

export default new PublishService();
