import {createCrossTabEventBus} from '../utils/crosstabeventbus';
import {onUnmounted} from 'vue';

const eventBus = createCrossTabEventBus('journey_composer');

export const Events = {
  JOURNEY_UPDATED: 'JOURNEY_UPDATED',
};

/**
 * Composable function providing a component scoped wrapper around eventbus subscriptions automatically cleaning subscriptions upon the component going out of scope
 * @return { { subscribeScoped } }
 */
export function useEventBus() {
  //Automatically dispose upon current component going out of scope
  const unsubscribeHandlers = [];
  const subscribeScoped = (key, callback) => unsubscribeHandlers.push(eventBus.subscribe(key, callback));

  //Execute all our unsubscribe handlers upon unmounted
  onUnmounted(() => unsubscribeHandlers.forEach(handler => handler()));

  return {
    subscribeScoped: subscribeScoped,
  };
}

export default eventBus;
