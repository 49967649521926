var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMounted, watch, reactive, h, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { DateTime } from 'luxon';
import { T, Uuid, acl, useDelete, cache, format } from '@dnx/core';
import apiReviews from '../app/api-generated/review';
import apiJourney from '../app/api-generated/journey';
import apiAreas from '../app/api-generated/areas';
import { defineStore } from 'pinia';
import DuplicationModal from './components/DuplicationModal.vue';
import lookup from './services/lookup';
const guidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
function useStore() {
    return Window.runtime.store;
}
// store-fy to only initialize once
const useReviewStore = defineStore('review-list', () => {
    const can = acl.useDnxAcl();
    const openReviews = ref([]);
    //Should we move this elsewhere?
    if (can.read.journeyreviews) {
        //Fire async request without awaiting, value will eventually become available
        apiReviews.listOpenForSelf().then(response => (openReviews.value = response.success ? response.value : []));
    }
    return { openReviews };
});
/**
 * Composable for determining how a journey preview should be displayed
 */
export function useDisplayConfiguration() {
    const store = useStore();
    const reviewStore = useReviewStore();
    return function getDisplayConfiguration(journey) {
        // @ts-ignore
        const normalizedStatus = journey.status.toLowerCase();
        let subTitle = '';
        switch (normalizedStatus) {
            case 'inactive': {
                return {
                    color: 'var(--text-primary-500, #868686)',
                    title: T('JOURNEY_OVERVIEW_INACTIVE'),
                };
            }
            case 'live': {
                if (journey.endDate) {
                    subTitle = T('JOURNEY_OVERVIEW_PUBLISHED_LIVE_UNTIL_SUBTITLE', {
                        enddate: format(journey.endDate, 'date'),
                    });
                }
                return {
                    icon: 'check',
                    color: 'var(--main-success, #00B866)',
                    title: T('JOURNEY_OVERVIEW_LIVE'),
                    subTitle,
                };
            }
            case 'pendingreview': {
                const isOpenForSelf = reviewStore.openReviews.some(r => r.reviewerId === store.getters['login/userId'] && r.revisionId === journey.revisionId);
                return {
                    icon: 'person-check',
                    color: 'var(--main-warning, #F1A400)',
                    title: T('JOURNEY_OVERVIEW_REVIEW'),
                    subTitle: isOpenForSelf ? T('JOURNEY_OVERVIEW_REVIEW_OPEN_SUBTITLE') : '',
                };
            }
            case 'reviewed': {
                return {
                    color: 'var(--main-warning, #F1A400)',
                    title: T('JOURNEY_OVERVIEW_REVIEWED_JOURNEY'),
                };
            }
            case 'published': {
                if (journey.isActive) {
                    if (journey.startDate && DateTime.fromISO(journey.startDate) > DateTime.now()) {
                        subTitle = T('JOURNEY_OVERVIEW_PUBLISHED_LIVE_FROM_SUBTITLE', {
                            startdate: format(journey.startDate, 'date'),
                        });
                    }
                    else if (journey.endDate && DateTime.fromISO(journey.endDate) < DateTime.now()) {
                        subTitle = T('JOURNEY_OVERVIEW_PUBLISHED_LIVE_UNTIL_SUBTITLE', {
                            enddate: format(journey.endDate, 'date'),
                        });
                    }
                }
                else {
                    subTitle = T('JOURNEY_OVERVIEW_PUBLISHED_DEACTIVATED_SUBTITLE');
                }
                const displayConfiguration = {
                    icon: 'check',
                    color: 'var(--datanext-blue, #2783C6)',
                    title: T('JOURNEY_OVERVIEW_PUBLISHED'),
                    subTitle,
                    tooltip: undefined,
                };
                let tooltipText = `${journey.name} - ${displayConfiguration.title}`;
                if (displayConfiguration.subTitle) {
                    tooltipText = `${tooltipText} (${displayConfiguration.subTitle})`;
                }
                displayConfiguration.tooltip = tooltipText;
                return displayConfiguration;
            }
            default:
                return {
                    icon: undefined,
                    color: 'var(--text-primary-500, #868686)',
                    title: T('JOURNEY_OVERVIEW_DRAFT'),
                };
        }
        //Default to draft style
    };
}
//Composable to check if user accessed page twice (across different tabs)
export function useSamePageCheck() {
    const userOnSamePage = ref(false);
    const route = useRoute();
    let location;
    let broadcaster = new BroadcastChannel('samepagecheck');
    let isNew = false;
    const update = () => {
        location = window.location.pathname;
        const guidResults = guidRegex.exec(location);
        isNew = guidResults && guidResults.length > 0 ? guidResults[0] === '00000000-0000-0000-0000-000000000000' : false;
        broadcaster.postMessage({ location, checked: false });
    };
    broadcaster.onmessage = ({ data }) => {
        if (data.location != location || isNew)
            return;
        if (!data.checked) {
            broadcaster.postMessage({ location, checked: true });
        }
        else {
            userOnSamePage.value = true;
        }
    };
    watch(() => route.fullPath, () => {
        update();
    });
    onMounted(() => {
        update();
    }),
        onUnmounted(() => {
            broadcaster.close();
        });
    return userOnSamePage;
}
export const useDuplicationModal = (options) => {
    const state = reactive({
        isLoading: false,
        isOpen: false,
        name: '',
    });
    const renderFunction = ref(h('div'));
    watch(state, () => {
        renderFunction.value = h(DuplicationModal, {
            isOpen: state.isOpen,
            popupTitle: options.title,
            cloneName: state.name,
            inputPlaceholder: options.placeholder,
            loadingState: state.isLoading,
            saveButtonText: options.saveButtonText,
            onClose: () => (state.isOpen = false),
            onSave: () => __awaiter(void 0, void 0, void 0, function* () {
                state.isLoading = true;
                try {
                    yield options.save(state.name);
                    state.isLoading = false;
                    state.isOpen = false;
                    if (options.onSuccess)
                        options.onSuccess();
                }
                catch (e) {
                    if (options.onError)
                        options.onError();
                }
            }),
            onChangeName: (newName) => {
                state.name = newName;
            },
        });
    });
    return {
        render: renderFunction,
        setName: (newName) => (state.name = newName),
        open: () => (state.isOpen = true),
    };
};
/** useDelete wrapper for journeys, exposes journey delete functionality */
function useJourneyDelete(options) {
    const list = useJourneyList();
    return useDelete(Object.assign({ promptTitle: 'JOURNEY_SETTINGS_DELETE_CONFIRM', promptMessage: 'JOURNEY_SETTINGS_REMOVE_CONFIRM', promptButtonText: 'JOURNEY_SETTINGS_DELETE', notificationSuccess: 'JOURNEY_SETTINGS_DELETED', delete: (target) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const journey = typeof target === 'string' ? list.journeys.find(j => j.id === target) : target;
            if (journey && journey.sharedBy && journey.sharedBy !== Uuid.Empty().toString()) {
                return { success: false, reason: T('ERROR_JOURNEY_SHARE_IN_USE') };
            }
            var response = yield apiJourney.delete(target);
            return { success: response.success, reason: T((_b = (_a = response.value) === null || _a === void 0 ? void 0 : _a.resourceKey) !== null && _b !== void 0 ? _b : T('ERROR_NONE')) };
        }) }, options));
}
export { useJourneyDelete as useDelete };
//maps the list of journey objects to contain sharing fields ('shareBy/shareWith....')
export const useJourneyList = defineStore('journey-list', () => {
    var _a, _b;
    const myDivision = (_b = (_a = useStore().getters['users/getCurrentUser']) === null || _a === void 0 ? void 0 : _a.areaId) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    const journeys = ref([]);
    const newestFirst = (lhs, rhs) => Date.parse(rhs.created) - Date.parse(lhs.created);
    function applySharing(journey) {
        journey.canShare = !!(journey.sharedBy &&
            (journey.sharedBy === myDivision || journey.sharedBy === Uuid.Empty().toString()));
        journey.sharedByMe = journey.sharedBy === myDivision;
        journey.sharedWithMe = !journey.sharedByMe && journey.sharedWith && journey.sharedWith.includes(myDivision);
        return journey;
    }
    Promise.all([cache.getFromApi('journey.list', apiJourney), cache.getFromApi('areas.get', apiAreas)]).then(([list, areas]) => {
        lookup.add('areas', areas);
        journeys.value = list.map(applySharing).sort(newestFirst);
    });
    const loadJourneys = () => __awaiter(void 0, void 0, void 0, function* () {
        cache.delete('journey.list');
        yield cache.getFromApi('journey.list', apiJourney).then((list) => {
            journeys.value = list.map(applySharing).sort(newestFirst);
        });
    });
    return { journeys, loadJourneys };
});
