import {OverviewProvider, TextStyles, acl, T} from '@dnx/core';
import {useDelete, useDisplayConfiguration} from './composables';
import {useJourneyList} from './composables';
import {share, clone} from './actions';

export function wire() {
  OverviewProvider.registerModuleConfiguration('journey-composer', {
    badge: {
      name: 'journey',
      color: 'var(--module-journey-composer, #D21460)',
    },
    color: 'var(--module-journey-composer, #D21460)',
    placeholderImage: '/assets/journey-placeholder.png',
    // createRoute: "/edit/00000000-0000-0000-0000-000000000000/",
    permission: 'journeys',
    topBarButtons: [
      {
        name: 'create-journey',
        link: '/journey-composer/edit/00000000-0000-0000-0000-000000000000/',
        get label() {
          return T('JOURNEY_COMPOSER_OVERVIEW_CREATE');
        },
      },
    ],

    searchableProperties: ['name', 'status', 'subTitle'],
  });

  OverviewProvider.registerRecordConfigurationProvider('journey-composer', journey => {
    const {loadJourneys} = useJourneyList();
    const deleteFlow = useDelete({deleted: () => loadJourneys()});
    const can = acl.useDnxAcl();
    const getDisplayConfiguration = useDisplayConfiguration();

    const editLink = `/journey-composer/edit/${journey.id}/${journey.revisionId}/`;

    const displayConfiguration = getDisplayConfiguration(journey);

    const badges = [];
    if (displayConfiguration.icon)
      badges.push({
        name: displayConfiguration.icon,
        color: displayConfiguration.color,
      });
    if (journey.sharedByMe)
      badges.push({
        name: '2-persons',
        color: 'var(--module-emdm-editor, #075087)',
      });
    if (journey.sharedWithMe)
      badges.push({
        name: 'person',
        color: 'var(--module-emdm-editor, #075087)',
      });

    const contextMenuOptions = [
      {
        name: 'edit',

        link: editLink,
        label: T('JOURNEY_COMPOSER_SHORTCUT_EDIT'),
        icon: {name: 'edit'},
      },
    ];

    if (journey.canEdit) {
      // cloneJourney
      contextMenuOptions.push({
        name: 'clone-journey',

        handler: () => clone(journey),
        label: T('JOURNEY_COMPOSER_SHORTCUT_DUPLICATE'),
        icon: {name: 'copy'},
      });
    }

    if (journey.canShare) {
      // showShareJourneyModal
      contextMenuOptions.push({
        name: 'share-journey',

        handler: () => share(journey),
        label: T('JOURNEY_COMPOSER_SHARE'),
        icon: {name: 'person-add'},
      });
    }

    if (can.delete.journeys && journey.status === 'Draft' && journey.canEdit) {
      contextMenuOptions.push({
        name: 'delete-journey',
        handler: () => deleteFlow(journey),
      });
    }

    let description = TextStyles.color(TextStyles.bold(displayConfiguration.title), displayConfiguration.color);
    if (displayConfiguration.subTitle) description += ' - ' + displayConfiguration.subTitle;

    return {
      name: journey.name,
      description: description,
      additionalBadges: badges,
      contextMenuOptions: contextMenuOptions,
      editRoute: editLink,
    };
  });
}
