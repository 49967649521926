import {defineStore} from 'pinia';
import api from '../../app/api-generated/journey';
import {isEmpty} from '../util';
import {useConfirmStore} from '../store/index';
import {T} from '@dnx/core';

const SessionModes = {
  View: 0,
  Edit: 1,
};

function startHeartbeat(sessionStore, journey) {
  let nextHeartbeatTimeout = undefined;
  let wasStopped = false;
  let consecutiveFailures = 0;

  const confirm = useConfirmStore();

  const stopHeartbeat = () => {
    wasStopped = true;
    clearTimeout(nextHeartbeatTimeout);
  };

  const sendHeartbeat = async () => {
    //Ignore
    if (wasStopped) return;

    const response = await api.sessionHeartbeat(journey.id, journey.revisionId);

    //Not ready yet?
    if (response.statusCode === 404) {
      consecutiveFailures = 0;
      return scheduleNextHeartbeat();
    }

    //Schedule next heartbeat on success
    if (response.success) {
      consecutiveFailures = 0;
      sessionStore.expires = response.value.expires;
      return scheduleNextHeartbeat();
    }

    //After 5 consecutive failures we may assume our session has died
    consecutiveFailures++;
    if (consecutiveFailures < 5) return scheduleNextHeartbeat();

    sessionStore.mode = SessionModes.View;
    stopHeartbeat();

    //Notify user of downgraded session
    //Ensure clean slate for our popover
    confirm.$reset();

    confirm.updateBoxProperties({
      title: T('APPSESSION_DOWNGRADED_TITLE'),
      message: T('APPSESSION_DOWNGRADED_MESSAGE'),
      status: 'warning',
      buttons: [{...confirm.defaultButton.ok, buttonStyle: 'primary-action', handler: reviveSession}],
    });
  };

  const scheduleNextHeartbeat = () => {
    //Schedule could've been called post stop invocation
    if (wasStopped) return;

    //Either directly (last call failed?), or 30 seconds prior to our session expiring
    let waitTimeInMs = Math.max(new Date(sessionStore.expires) - new Date() - 30000, 500);
    if (!Number.isFinite(waitTimeInMs)) waitTimeInMs = 30000; //Default to 30 seconds, invalid date?

    // waitTimeInMs = 1000;
    // console.log('Scheduling next heartbeat in: ' + waitTimeInMs / 1000 + ' seconds');

    nextHeartbeatTimeout = setTimeout(sendHeartbeat, waitTimeInMs);
  };

  //Give the user a way to attempt to restart their session post closure
  const reviveSession = async () => {
    confirm.updateBoxProperties({
      title: T('APPSESSION_DOWNGRADED_TITLE'),
      message: T('APPSESSION_UPGRADING'),
      status: 'info',
      buttons: [],
    });

    await sessionStore.start(journey);

    //Upgrade succeeded to upgrade
    if (!sessionStore.isViewMode) {
      confirm.$reset();
    } else {
      confirm.updateBoxProperties({
        title: T('APPSESSION_DOWNGRADED_TITLE'),
        message: T('APPSESSION_UPGRADE_FAILED'),
        status: 'error',
        buttons: [
          {
            name: T('CONFIRM'),
            buttonStyle: 'white',
            handler: confirm.toggleConfirmBox,
          },
        ],
      });
    }
  };

  //Schedule our first heartbeat on creation
  scheduleNextHeartbeat();

  return stopHeartbeat;
}

/**
 * Interface for interacting with current journey app session
 *
 * App sessions determine whether the current user may edit the journey or not
 * */
export const useJourneySessionStore = defineStore('journey-session', {
  state: () => ({
    mode: SessionModes.View,
    expires: undefined,
    editedBy: undefined,

    _stopHeartbeat: undefined,
  }),
  getters: {
    isViewMode() {
      return this.mode === SessionModes.View;
    },
    isEditedByOtherUser() {
      return this.isViewMode && !isEmpty(this.editedBy);
    },
  },
  actions: {
    async start(journey) {
      //New journeys are always started in edit mode
      if (journey.isNew) {
        this.mode = SessionModes.Edit;
        return;
      }

      //Non-draft is always assumed to be 'view', any changes made will yield a new draft
      if (!journey.isDraft) return;

      //Initialize/extend our session for the current journey
      this.$state = (await api.startSession(journey.id, journey.revisionId)).value;

      if (this.mode !== SessionModes.Edit) return;

      //We started an edit session, start our session heartbeat
      this._stopHeartbeat = startHeartbeat(this, journey);
    },
    async terminate(journey) {
      if (journey.isNew || this.isViewMode) return;

      if (this._stopHeartbeat) this._stopHeartbeat();
      await api.terminateSession(journey.id, journey.revisionId);
    },
  },
});
