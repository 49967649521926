// Reverse key<->value so object becomes lookup
export const asLookup = obj => Object.entries(obj).reduce((acc, [key, value]) => ((acc[value] = key), acc), {});

// Clean a value for use as a key (lowercase, remove space & dashes)
export const cleanKey = value => value?.toLowerCase().replace(/ -/gi, '');

// Allow maps to be stringified/parsed (JSON.stringify(data, mapReplacer))
export function mapReplacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  } else {
    return value;
  }
}

// Allow maps to be stringified/parsed (JSON.parse(data, mapReviver))
export function mapReviver(key, value) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

// Is the object empty (null,undefined,empty array, empty guid, empty string, etc)
export function isEmpty(id) {
  if (undefined === id) return true;
  if (null === id) return true;
  if ('00000000-0000-0000-0000-000000000000' === id) return true;
  if (typeof id === 'string') return '' === id;
  if (Array.isArray(id)) return 0 === ~~id.length;
  if (id instanceof Map) return id.size === 0;
  if (id instanceof Set) return id.size === 0;
  if (typeof id === 'object') return ~~Object.keys(id)?.length === 0;
  return false;
}

// Create a slug from a string. slug('appeltaart',6) => 'ap..rt'
export function slug(value, len) {
  if (typeof value !== 'string' || value.length <= len || len < 4) return value;
  const lhs = Math.floor((len - 1) / 2);
  return `${value.substring(0, lhs)}..${value.substring(value.length - (len - 2 - lhs))}`;
}

// Toggle or set stylesheet
export function enableStyleSheet(name, setting) {
  const sheets = [...document.styleSheets];
  const sheet = sheets.find((_, css) => css.title === name);
  if (sheet) {
    sheet.disabled = setting === undefined ? !sheet.disabled : setting;
  }
}

/** Hydrate a field by parsing the json string and assigning it to the same field. */
export function hydrate(obj, field = 'config') {
  if (isEmpty(obj)) return obj;
  if (isEmpty(obj[field])) return obj;

  if (typeof obj[field] === 'string') {
    try {
      const config = JSON.parse(obj[field]);
      obj[field] = config;
    } catch (e) {
      console.error('Invalid config in brick definitions. Config ignored');
    }
  }
  return obj;
}

/** Pick a selection of fields from an object Ex: for(f in pickFrom({a,b,c:2}, 'a', 'c') console.log(f)) prints a,2*/
export function* pickFrom(obj, ...fields) {
  if (isEmpty(obj) || isEmpty(fields)) {
    return;
  }

  for (const field of fields) {
    yield obj[field];
  }
}

/** Return an array containing all items contained in both the left and right array */
export function intersection(left, right) {
  return left.filter(item => right.includes(item));
}

/** Return an array containing all items only present in the left array */
export function except(left, right) {
  return left.filter(item => !right.includes(item));
}

export const changeColorBrightness = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

/**
 * Get a unique key for the given object
 * @param {Object} obj
 * @return {number}
 */
export function keyFor(obj) {
  keyFor.store ??= new WeakMap();
  keyFor.current ??= 0;

  if (keyFor.store.has(obj)) return keyFor.store.get(obj);
  keyFor.store.set(obj, ++keyFor.current);
  return keyFor.current;
}
