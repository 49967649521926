var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
import datamodel from '../services/datamodel';
import api from '../../app/api-generated/journey';
import { cache } from '@dnx/core';
import lookup from '../services/lookup';
import { isEmpty } from '../util';
import { DateTime } from 'luxon';
import { useJourneySessionStore, useReviewStore } from '.';
import { JourneyBrickType } from '../../app/api-generated/enums';
import { T, Uuid, $can } from '@dnx/core';
import publish from '../services/publish';
export const useJourneyStore = defineStore('journey', {
    state: () => {
        var _a, _b;
        return ({
            id: '00000000-0000-0000-0000-000000000000',
            revisionId: undefined,
            isNew: true,
            status: 'Draft',
            name: `${T('JOURNEY')} ${DateTime.now().toFormat('yyyy-MM-dd HH:mm')}`,
            world: undefined,
            type: ((_a = lookup['journey-type']) === null || _a === void 0 ? void 0 : _a.id) || 0,
            description: '',
            startDate: undefined,
            endDate: undefined,
            isActive: false,
            divisionId: ((_b = lookup['areas']) === null || _b === void 0 ? void 0 : _b.id) || 0,
            config: undefined,
            brickComments: [],
            version: 0,
            groups: [],
            isPublishable: false,
            isLatestRevision: false,
            connectedJourneys: [],
            bricks: {},
            sharedWithMe: false,
            sharedWith: [],
            canEdit: true,
            session: useJourneySessionStore(),
            review: useReviewStore(),
            isNonProd: false,
            parentId: undefined
        });
    },
    getters: {
        isDraft(state) {
            return state.status == 'Draft';
        },
        isInReview(state) {
            return state.status === 'PendingReview' || state.status === 'Reviewed';
        },
        isPublished(state) {
            return state.status === 'Published' || state.status === 'Live';
        },
        canRequestReview(state) {
            if ($can.not.create.journeyreviews)
                return false;
            if (!this.canEdit)
                return false;
            return this.isDraft && !state.isNew;
        },
        canDeactivate(state) {
            if ($can.not.write.activatejourneys)
                return false;
            if (!this.canEdit)
                return false;
            return this.isPublished && state.isActive;
        },
        canDelete(state) {
            //Only drafts support deletion
            return $can.delete.journeys && this.isDraft && !this.isNew && !this.canEdit;
        },
        canPublish(state) {
            if ($can.not.write.activatejourneys)
                return false;
            if (!this.canEdit)
                return false;
            return !this.issues.length && state.isPublishable;
        },
        canSave(state) {
            if (state.isNew && $can.not.create.journeys)
                return false;
            if (!state.isNew && $can.not.write.journeys)
                return false;
            if (!this.canEdit)
                return false;
            //Draft journeys may only be edited by one single person at a time
            //this is tracked by our session interface
            if (this.isDraft)
                return !this.session.isViewMode;
            //Current user still has to review current journey
            //DNX3745 if (this.review.userAllowedToRequestReview[state.id]) return false;
            //Only the latest revision may be used for basing new drafts off of
            return state.isLatestRevision;
        },
        issues(state) {
            return datamodel.validate(state);
        },
        issuesGrouped() {
            const groupedIssues = this.issues.reduce((accumulator, issue) => {
                const key = issue.group == 'brick' ? issue.context.id : 'journey';
                const group = accumulator.get(key);
                if (group) {
                    group.reasons.push(issue.reason);
                }
                else {
                    accumulator.set(key, {
                        name: issue.group == 'brick' ? issue.context.name || T(issue.context.translation) : T('JOURNEY'),
                        reasons: [issue.reason],
                        icon: issue.group == 'brick' ? issue.context.icon : 'gear',
                        iconBackgroundColor: issue.group == 'brick' ? issue.context.color : '#ffffff00',
                        iconColor: issue.group == 'brick' ? '#ffffff' : undefined,
                    });
                }
                return accumulator;
            }, new Map());
            return Array.from(groupedIssues.values());
        },
    },
    actions: {
        load(id, revisionId) {
            return __awaiter(this, void 0, void 0, function* () {
                this.$reset();
                if (isEmpty(id)) {
                    this.isNew = true;
                    this.id = Uuid.NewUuid().toString();
                }
                else {
                    const cachekey = `journey.${id}.${revisionId}.getById`;
                    const cached = cache.has(cachekey) ? yield cache.getFromApi(cachekey, api, id, revisionId) : undefined;
                    const latest = yield api.getById(id, revisionId);
                    const journey = (latest.success ? latest.value : cached);
                    this.$state = datamodel.unpack(journey);
                    this.isNew = false;
                    this.canEdit = journey.canEdit;
                    this.sharedWithMe = journey.sharedWithMe;
                    this.sharedWith = [...journey.sharedWith];
                    this.isNonProd = Object.keys(latest.headers).indexOf('x-jc-non-prod-env') !== -1;
                    if (this.revisionId && this.revisionId !== revisionId) {
                        yield cache.update(`journey.${this.id}.${this.revisionId}.getById`, journey);
                        yield cache.delete(cachekey);
                    }
                }
                yield this.startSession();
            });
        },
        startSession() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.session.start(this);
            });
        },
        terminateSession() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.session.terminate(this);
            });
        },
        // Return the bricks used by a connection
        getBricks(connection) {
            return {
                source: this.bricks[connection.sourceBrickId],
                target: this.bricks[connection.destinationBrickId],
            };
        },
        // Return bricks within distance from a source set. (selectedBrick, 1) = single select highlight
        // Adding the connectionids is optional
        getBricksWithinDistance(from, distance, includeConnections) {
            if (~~distance < 0 || isEmpty(from))
                return [];
            let result = [...(Array.isArray(from) ? from : [from])];
            let prevLength = -1;
            for (let d = 0; d < distance && prevLength !== result.length; d++) {
                prevLength = result.length;
                result = result.reduce((set, id) => {
                    if (this.bricks[id]) {
                        for (const conn of Object.values(this.bricks[id].outgoing)) {
                            if (!set.includes(conn.id) && includeConnections)
                                set.push(conn.id);
                            if (!set.includes(conn.sourceBrickId))
                                set.push(conn.sourceBrickId);
                            if (!set.includes(conn.destinationBrickId))
                                set.push(conn.destinationBrickId);
                        }
                        for (const conn of Object.values(this.bricks[id].incoming)) {
                            if (!set.includes(conn.id) && includeConnections)
                                set.push(conn.id);
                            if (!set.includes(conn.sourceBrickId))
                                set.push(conn.sourceBrickId);
                            if (!set.includes(conn.destinationBrickId))
                                set.push(conn.destinationBrickId);
                        }
                    }
                    return set;
                }, result);
            }
            return result;
        },
        // Get connection by id
        getConnection(id) {
            if (isEmpty(id))
                return undefined;
            for (const brick of Object.values(this.bricks)) {
                if (brick.outgoing && brick.outgoing[id]) {
                    return brick.outgoing[id];
                }
            }
            return undefined;
        },
        // Delete a connection by id
        deleteConnection(id) {
            var _a;
            const connection = this.getConnection(id);
            if (!connection) {
                return;
            }
            const { source, target } = this.getBricks(connection);
            //When connection source is A/B Test, remove connection from routes
            if (source.type == JourneyBrickType.ABTest) {
                if (source.config) {
                    source.config.routes = (_a = source.config.routes) === null || _a === void 0 ? void 0 : _a.filter((route) => route.connectionId != id);
                }
            }
            delete source.outgoing[connection.id];
            delete source.connections[connection.id];
            delete target.incoming[connection.id];
            delete target.connections[connection.id];
        },
        duplicateJourney(id_1, cloneName_1) {
            return __awaiter(this, arguments, void 0, function* (id, cloneName, fromDatabase = false) {
                const journeyStore = useJourneyStore();
                const model = fromDatabase ? null : publish.serialize(journeyStore);
                const response = yield api.copy(id, model, cloneName);
                return response;
            });
        }
    },
});
