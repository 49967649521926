import {createWebHistory, createRouter} from 'vue-router';
import {useJourneyStore} from './store';

/** Close window */
export function closeWindow(vmOrRouter) {
  if (window.history.length < 2) window.close();
  else {
    const route = vmOrRouter.$route || vmOrRouter.currentRoute.value;
    const router = vmOrRouter.$router || vmOrRouter;

    let overview = route.fullPath;
    if (overview.indexOf('edit/') > 0) {
      overview = overview.substring(0, overview.indexOf('/edit'));
      router.replace(overview);
    } else router.back();
  }
}

/** Open window */
export function openWindow(vmOrRouter, routeOrUrl, target = '_blank') {
  const url = routeOrUrl.href || routeOrUrl.path || routeOrUrl;

  window.open(url, target);

  // if (!vmOrRouter) {
  //   window.location = url;
  //   return;
  // }
  //
  // if (vmOrRouter.$router) vmOrRouter.$router.push(routeOrUrl);
  // else vmOrRouter.push(routeOrUrl);
  //
  // eslint-disable-next-line no-undef
  // if (module.hot) {
  //   if (!vmOrRouter) {
  //     window.location = url;
  //     return;
  //   }
  //
  //   if (vmOrRouter.$router) {
  //     vmOrRouter.$router.push(routeOrUrl);
  //   } else {
  //     vmOrRouter.push(routeOrUrl);
  //   }
  // } else {
  //   window.open(url, target);
  // }
}

export const routes = [
  {
    path: '/',
    name: 'journey-composer',
    component: () => import('./pages/Overview.vue'),
    meta: {sidebar: true},
  },
  {
    path: '/overview/v2',
    name: 'journey-composer-v2',
    component: () => import('./pages/OverviewV2'),
    meta: {sidebar: true},
  },
  {
    path: '/edit/:id?/:revisionId?',
    name: 'journey-composer-edit',
    component: () => import('./pages/Composer.vue'),
    meta: {
      // temporarily disabled, screws with routing etc. open in current tab when using hot module reloading
      target: '_blank', //module.hot ? undefined :  '_blank',
      sidebar: false,
      confirmLeave: () => {
        return useJourneyStore().canSave;
      },
      /* , authState: "onlyAuthenticated" */
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
