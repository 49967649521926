import {BrickDefinition} from '../types/brick-definition';
import {Definitions as CheckpointDefinitions, FieldCondition, Types} from '../types/checkpoint-definition';
import brickTypes, {supportsComments, inDisplayOrder} from '../types/brick-types';
import TypeHierarchy from '../types/type-hierarchy';
import {cleanKey, isEmpty} from '../util';
import lookup from './lookup';
import Validation from '../services/validation';
import {cache} from '@dnx/core';

import api_journey from '../../app/api-generated/journey';
import api_brick from '../../app/api-generated/journeyBrick';
import api_areas from '../../app/api-generated/areas';
import api_audiences from '../../app/api-generated/audiences';
import api_settings from '../../app/api-generated/platformSettings';

import {T, Uuid} from '@dnx/core';
import ExtraConditions from '../types/touchpoint-field-definition';

const api = {
  journey: api_journey,
  brick: api_brick,
  areas: api_areas,
  audiences: api_audiences,
  settings: api_settings,
};

const audienceType = {
  new: 1,
  existing: 2,
};

function parseDefinitions(data) {
  let model = data.reduce((all, model) => {
    all[model.name.toLowerCase()] = new BrickDefinition(model);
    return all;
  }, {});
  model = {...Object.fromEntries(inDisplayOrder(model))};
  return model;
}

function ensureId(brick) {
  if (!brick.id || brick.id == new Uuid()) {
    brick.isNew = true;
    brick.id = Uuid.NewUuid().toString();
  }

  brick.def = () => definitions.get(brick.typeName);
  brick.options = name => brick.def().getOptions(brick, name);
  brick.supports = name => brick.def().supports(name, brick);
}

/** Update custom conditions by adding required fields and removing unknown fields based on the touchpoint type */
function updateCustomConditions(brick) {
  const defs = brick.fields;
  if (!defs) {
    if (brick.config) {
      brick.config.customConditions = [];
    }
    return;
  }

  let list = [...(brick.config?.customConditions || [])];
  let changed = list.length !== brick.config?.customConditions?.length;

  // Add required conditions, fill empty values with defaults
  for (const def of defs.values()) {
    const condition = list.find(itm => itm.id === def.id);

    if (def.required && !condition) {
      list.push({value: def.default, type: def.field, id: def.id});
      changed = true;
    }

    if (def.default && condition && isEmpty(condition.value)) {
      condition.value = def.default;
      changed = true;
    }
  }

  // Update or remove remaining unknown conditions.
  const killlist = [];
  const allfields = [...defs.values()];
  for (const condition of list) {
    if (!defs.has(condition.id)) {
      changed = true;

      // Id no longer exists. Try to find a condition in this fieldset with the same field/type (page_url).
      // condition might be recreated in the db, moved from subtype to parenttype (or vice versa) or the id
      // was never valid (legacy import/bug)

      const other = allfields.find(d => d.field === condition.type);
      if (other) {
        const existing = list.find(itm => itm.id === other.id);
        if (existing) {
          if (isEmpty(existing.value) || existing.value === defs[existing.id].default) {
            existing.value = condition.value;
          }
          killlist.push(condition);
        } else {
          condition.id = other.id;
        }
      } else {
        killlist.push(condition);
      }
    }
  }

  if (killlist.length > 0) {
    list = list.filter(itm => !killlist.includes(itm));
  }

  if (changed) {
    brick.config.customConditions = list;
  }
}

// Add helper props for use vue components
function addHelpers(brick) {
  brick.incoming = {};
  brick.outgoing = {};
  brick.connections = {};
  if (!brick.description) brick.description = '';
  if (!brick.remark) brick.remark = '';
  if (typeof brick.config !== 'object') brick.config = {};
}

const initializers = {
  /** Add hierarchy to brick  */
  addTypeHierarchy: brick => {
    const hierarchy = brick.def().hierarchy;
    brick.parentType = brick.config.touchpointParentType;
    brick.subType = brick.config.touchpointSubType;
    if (brick.config.touchPointTypeId) brick.subType = hierarchy.findByTouchId(brick.config.touchPointTypeId)?.name;
    brick.variation = brick.config.touchpointVariation;
    const ui = hierarchy.get(brick.parentType, brick.subType, brick.variation);
    brick.icon = ui.icon ?? brick.icon ?? 'information-circle';

    brick.updateTypes = (instance, parentType, subType, variation) => {
      const changed =
        instance.parentType !== parentType || instance.subType !== subType || instance.variation !== variation;

      if (changed) {
        if (instance.parentType !== parentType) {
          delete instance.config.campaignId;
        }
        instance.parentType = parentType;
        instance.subType = parentType ? subType : undefined;
        instance.variation = subType ? variation : undefined;
        const ui = hierarchy.get(brick.parentType, brick.subType, brick.variation);
        instance.icon = ui.icon ?? brick.icon ?? 'information-circle';
        instance.fields = ExtraConditions.getFieldMap(instance);
        updateCustomConditions(instance);
        instance.config.touchpointParentType = parentType;
        instance.config.touchpointSubType = subType;
        instance.config.touchpointVariation = variation;
        instance.config.touchpointTypeId = hierarchy.getTouchId(parentType, subType);

        const options = brick.def().getOptions(brick, 'subType');

        if (options?.length === 1) {
          instance.updateTypes(brick, parentType, options[0].value, undefined);
        }
      }
    };
  },
  /** Add definitions for extra fields (aka conditions) at creation! */
  addExtraFields: brick => {
    const fieldMap = ExtraConditions.getFieldMap(brick);
    if (!isEmpty(fieldMap)) {
      brick.fields = fieldMap;
      updateCustomConditions(brick);
    }
  },
  /** canConnectFrom: can this brick connect FROM the other (other => brick) */
  noIncoming: brick =>
    (brick.canConnectFrom = () =>
      Validation.error(`Cannot connect to ${isEmpty(brick.name) ? brick.typeName : brick.name}.`)),
  /** canConnectTo: can this brick connect TO the other (brick => other) */
  noOutgoing: brick =>
    (brick.canConnectTo = () =>
      Validation.error(`Cannot connect from ${isEmpty(brick.name) ? brick.typeName : brick.name}.`)),
  notSameType: brick =>
    (brick.canConnectTo = other =>
      other?.typeName !== brick.typeName ? Validation.success() : Validation.error('Audiences do not connect')),
  /* Remove checkpoint support for connections with this brick */
  noCheckpoints: brick => (brick.checkpointsDisabled = true),
};

const validators = {
  touchpoint: brick => {
    if (
      isEmpty(brick.parentType) ||
      isEmpty(brick.subType) ||
      (brick.supports('email-campaign') && isEmpty(brick.config.requiredConsentPurposeId))
    ) {
      return Validation.critical(T('VALIDATOR_TOUCHPOINT_SETTING_MISSING'), 'brick', brick);
    }
    return Validation.success();
  },
  audience: brick =>
    isEmpty(brick.config.audienceId)
      ? Validation.critical(T('VALIDATOR_AUDIENCE'), 'brick', brick)
      : Validation.success(),
  goal: brick => {
    if (isEmpty(brick.config.goalType)) {
      return Validation.critical(T('VALIDATOR_GOAL'), 'brick', brick);
    }

    if (brick.config.setupAudience) {
      if (!brick.config.audienceSettings?.audienceType) {
        return Validation.critical(T('VALIDATOR_GOAL_EMPTY_AUDIENCE_TYPE'), 'brick', brick);
      }

      if (
        (brick.config.audienceSettings?.audienceType == 1 && !brick.config.audienceSettings?.newAudienceName) ||
        (brick.config.audienceSettings?.audienceType == 2 && !brick.config.audienceSettings?.existingAudience)
      ) {
        return Validation.critical(T('VALIDATOR_GOAL_EMPTY_AUDIENCE'), 'brick', brick);
      }
    }

    return Validation.success();
  },
  journey: brick =>
    !brick.config.parentJourneyId && isEmpty(brick.config.journeyId)
      ? Validation.critical(T('VALIDATOR_JOURNEY'), 'brick', brick)
      : Validation.success(),
  abroutes: validateAbRoutes,
  checkpoints: validateCheckpoints,
  customconditions: validateCustomConditions,
  nameRequired: brick =>
    isEmpty(brick.name) ? Validation.error(T('VALIDATOR_BRICK_NAME_REQUIRED'), 'brick', brick) : Validation.success(),
  consentRequired: requireConsent,
  campaignRequired: requireCampaignId,
};

/** Validate the custom conditions against the brick.fields  */
function validateCustomConditions(brick) {
  if (isEmpty(brick?.fields)) {
    return Validation.success();
  }

  const issues = [];
  for (const definition of brick.fields.values()) {
    const condition = brick.config?.customConditions?.find(itm => itm.type === definition.field);

    if (condition) {
      if (isEmpty(condition.value)) {
        issues.push(Validation.error(`Condition ${definition.name} has no value`));
      }
    } else {
      if (definition.required) {
        issues.push(Validation.error(`Condition ${definition.name} is required`));
      }
    }
  }

  return isEmpty(issues) ? Validation.success() : issues;
}

function requireCampaignId(brick) {
  if (isEmpty(brick.config.campaignId)) {
    if (brick.supports('email-campaign')) {
      return Validation.critical(T('VALIDATOR_EMAIL_CAMPAIGN_REQUIRED'), 'brick', brick);
    }
    if (brick.supports('phone-campaign')) {
      return Validation.critical(T('VALIDATOR_PHONE_CAMPAIGN_REQUIRED'), 'brick', brick);
    }
    if (brick.supports('dm-campaign')) {
      return Validation.critical(T('VALIDATOR_DM_CAMPAIGN_REQUIRED'), 'brick', brick);
    }
    if (brick.supports('sms-campaign')) {
      return Validation.critical(T('VALIDATOR_SMS_CAMPAIGN_REQUIRED'), 'brick', brick);
    }
  }
  return Validation.success();
}

function requireConsent(brick) {
  if (!brick.supports('consent')) return;

  if (isEmpty(brick.config.requiredConsentPurposeId)) {
    return Validation.critical(T('VALIDATOR_CONSENT_REQUIRED'), 'brick', brick);
  }
}

function validateAbRoutes(brick) {
  const routes = brick.config.routes;

  if (!routes || routes.length < 2) {
    return Validation.critical(T('VALIDATOR_ABTEST_ROUTES'), 'brick', brick);
  }

  const totalPercent = routes.reduce((previousNumber, currentRoute) => {
    return previousNumber + parseInt(currentRoute.percentage);
  }, 0);

  if (totalPercent != 100) {
    return Validation.critical(T('ABTEST_SETTINGS_PERCENTAGE_VALIDATE'), 'brick', brick);
  }

  return Validation.success();
}

/** Validate the checkpoints using the validate in the checkpoint  */
function validateCheckpoints(brick) {
  if (isEmpty(brick?.connections)) {
    return Validation.success();
  }

  let issues = [];
  const validate = (connection, filter) => {
    const filterName = Types[filter];
    if (!filterName)
      return Validation.critical(`Unknown filter`, `${brick.name || brick.typeName}.${filter}`, {brickId: brick.id});
    if (!CheckpointDefinitions[filterName])
      return Validation.critical(`Unknown checkpoint definition`, `${brick.name || brick.typeName}-${filterName}`, {
        brickId: brick.id,
      });

    if (!connection?.checkpoints?.[filterName]) return Validation.success();

    const checkpoint = connection.checkpoints[filterName];
    const check = CheckpointDefinitions[filterName].validate;
    if (typeof check === 'function') {
      return check(checkpoint);
    } else {
      // If no validator, fall back to update check
      const update = CheckpointDefinitions[filterName].update;
      if (typeof update === 'function') {
        if ('' === update(checkpoint))
          return Validation.error(`Could not update`, `${brick.name || brick.typeName}-${filterName}`, {
            brickId: brick.id,
          });
      }
    }
    return Validation.success();
  };

  for (const connection of Object.values(brick.outgoing)) {
    for (const filter in Types) {
      const issue = validate(connection, filter);
      if (issue) {
        issues = issues.concat(issue);
      }
    }
  }

  return isEmpty(issues) ? Validation.success() : issues;
}

const map = {
  [brickTypes.touchpoint]: {
    init: [initializers.addTypeHierarchy, initializers.addExtraFields],
    validator: [
      validators.touchpoint,
      validators.customconditions,
      validators.campaignRequired,
      validators.consentRequired,
    ],
  },
  [brickTypes.audience]: {
    init: [initializers.noIncoming, initializers.notSameType],
    validator: [validators.audience],
  },
  [brickTypes.goal]: {
    init: [initializers.noOutgoing, initializers.noCheckpoints],
    validator: [validators.goal],
  },
  [brickTypes.journey]: {
    init: [
      brick => {
        if (brick.config?.isChildJourney) {
          initializers.noIncoming(brick);
        } else {
          initializers.noOutgoing(brick);
          initializers.noCheckpoints(brick);
        }
      },
    ],
    validator: [validators.journey],
  },
  [brickTypes.abTest]: {
    init: [],
    validator: [validators.abtest, validators.abroutes],
  },
};

class BrickDefinitions {
  constructor() {
    this.bricks = {};
  }

  addInitializers(definition) {
    definition.initializer = [ensureId, addHelpers, ...(map[definition.type]?.init ?? [])];
  }

  addValidators(definition) {
    definition.validator = [
      ...(map[definition.type]?.validator ?? []),
      validators.checkpoints,
      validators.nameRequired,
    ];
  }

  async load() {
    // Get all settings
    lookup.add('all-settings', await cache.getFromApi('settings.all.get', api.settings, 'JourneyComposer'));

    lookup.add('areas', await cache.getFromApi('areas.get', api.areas));
    lookup.add('division', await cache.getFromApi('areas.get', api.areas));

    lookup.add('audiences', await cache.getFromApi('audiences.getDefaultSummaries', api.audiences));
    lookup.add('audience-attributes', await cache.getFromApi('audiences.getAttributes', api.audiences));

    lookup.add('goal-types', await cache.getFromApi('brick.getGoalTypes', api.brick));

    lookup.add('list-journeys', await cache.getFromApi('journey.list', api.journey));

    const allFields = await cache.getFromApi('journey.getCustomFilterFields', api.journey);
    const allConditionDatasets = await cache.getFromApi('journey.getConditionDatasets', api.journey);

    const allCustomFields = await cache.getFromApi('brick.getCustomFields', api.brick);

    //DEV: We want these objects as-is!
    delete allFields._isMultiResultSet;
    delete allConditionDatasets._isMultiResultSet;
    delete allCustomFields._isMultiResultSet;

    lookup.add('audience-filter-field-types', allFields);
    lookup.add('condition-datasets', allConditionDatasets);

    lookup.add('worlds', await cache.getFromApi('journey.getWorlds', api.journey));
    lookup.add('types', await cache.getFromApi('journey.getTypes', api.journey));
    lookup.add('email-campaigns', await cache.getFromApi('journey.email.getCampaigns', api.journey, 'email'));
    lookup.add('steam-campaigns', await cache.getFromApi('journey.steam.getCampaigns', api.journey, 'steam'));
    lookup.add('dm-campaigns', await cache.getFromApi('journey.dm.getCampaigns', api.journey, 'dm'));

    lookup.add('sms-campaigns', await cache.getFromApi('journey.sms.getCampaigns', api.journey, 'sms'));

    // datefield to use for delay (startDate/endDate/Retentie_opzegdatum)
    lookup.add(
      'delay-datefield-options',
      (function asOptions(arr) {
        return arr.map(x => ({value: x.id, label: T(x.translationKey)}));
      })(await cache.getFromApi('brick.getTouchpointDelayDateColumns', api.brick))
    );
    lookup.add('delay-time-units', await cache.getFromApi('brick.getTimeUnits', api.brick));
    lookup.add('time-units', await cache.getFromApi('brick.getTimeUnits', api.brick));

    lookup.add('list-journeys', await cache.getFromApi('journey.list', api.journey));

    FieldCondition.initialize();
    ExtraConditions.initialize(allCustomFields);

    this.bricks = parseDefinitions(await cache.getFromApi('brick.getDefinitions', api.brick));

    const touchpoint = this.bricks['touchpoint'];
    touchpoint.hide.push('hierarchy');

    touchpoint.hierarchy = new TypeHierarchy(await cache.getFromApi('brick.getTouchpointTypes', api.brick));
    touchpoint.addOptions('parentType', brick => touchpoint.hierarchy.options(brick.parentType));
    touchpoint.addOptions('subType', brick => touchpoint.hierarchy.options(brick.parentType, brick.subType));
    touchpoint.addOptions('variation', brick =>
      touchpoint.hierarchy.options(brick.parentType, brick.subType, brick.variation)
    );
    touchpoint.addOptions('email-campaign', () => lookup['email-campaigns']);
    touchpoint.addOptions('phone-campaign', () => lookup['steam-campaigns']);
    touchpoint.addOptions('dm-campaign', () => lookup['dm-campaigns']);
    touchpoint.addOptions('sms-campaign', () => lookup['sms-campaigns']);

    // TODO: This should be different definitions (multiple of type touchpoint) so this can be added from the DB!
    const existsIn = (value, options) => (options || []).find(option => cleanKey(value) === cleanKey(option));
    touchpoint.addFeature('email-campaign', brick => existsIn(brick?.parentType, ['E-mail', 'Email']));
    touchpoint.addFeature(
      'dm-campaign',
      brick =>
        existsIn(brick?.parentType, ['DM', 'Direct Mail', 'Print']) ||
        existsIn(brick?.subType, ['e-mail verzonden', 'Direct Mail'])
    );
    touchpoint.addFeature('phone-campaign', brick => existsIn(brick?.parentType, ['Phone', 'Telefoon']));
    touchpoint.addFeature('sms-campaign', brick => existsIn(brick?.parentType, ['SMS']));
    touchpoint.addFeature('file-per-title', brick => existsIn(brick?.parentType, ['Organic', 'Print']));
    touchpoint.addFeature('consent', brick => existsIn(brick?.parentType, ['E-mail', 'Email', 'Print']));
    touchpoint.addFeature('custom-conditions', brick => !isEmpty(brick.fields));

    touchpoint.config = {
      touchpointParentType: null,
      touchpointSubType: null,
    };

    const goal = this.bricks['goal'];
    goal.addOptions('goalType', () => lookup['goal-types']);
    goal.addOptions('unit', () => lookup['time-units']);

    Object.values(this.bricks).forEach(def => {
      def.addOptions('checkpoints', brick => {
        if (brick.checkpointsDisabled) return [];
        return Object.keys(CheckpointDefinitions);
      });

      def.addFeature('checkpoints', brick => brick.options('checkpoints').length !== 0);

      this.addInitializers(def);
      this.addValidators(def);
      if (supportsComments.includes(def.type)) def.addFeature('comments');
    });
  }

  nameFromType(type) {
    const typemap = ['unknown', 'audience', 'journey', 'touchpoint', 'legacy', 'audienceFilter', 'abTest', 'goal'];
    return typemap[type] ?? 'unknown';
  }

  add(definition) {
    if (typeof definition?.name !== 'string' || definition.name.length < 1) throw new Error('Name is required');
    this.bricks[this.getKey(definition.name)] = definition;
  }

  getKey(value) {
    let key = undefined;
    if (typeof value === 'function') key = value();
    if (typeof value === 'string') key = value;
    if (typeof value === 'object') key = value.typeName ?? value.name;
    //DEV: Journies contain ab-test,abTest,AB test
    if (key?.toLowerCase() === 'abtest' || key?.toLowerCase() === 'ab-test') key = 'AB test';
    return key?.toLowerCase();
  }

  delete(definition) {
    const key = this.getKey(definition);
    if (key == undefined || key == null) return;

    this.bricks.delete(key);
  }

  exists(name) {
    return this.get(name) !== undefined;
  }

  get(name) {
    return this.bricks[this.getKey(name)];
  }

  options(instance, field) {
    return this.get(instance.typeName)?.getOptions(instance, field);
  }

  create(name, model) {
    const definition = this.get(name);
    definition.name = '';
    const brick = {
      ...definition,
      id: undefined,
      ...model,
      typeName: name,
    };

    // Hide definition only fields.
    if (definition.hide) {
      for (const field of definition.hide) delete brick[field];
      delete brick.hide;
    }

    // Prevent bricks from sharing the same config object by creating a deep clone if it was supplied by our definition
    if (definition.config) {
      brick.config = JSON.parse(JSON.stringify(brick.config));
    }

    return brick;
  }

  get checkpoints() {
    return CheckpointDefinitions;
  }
}

const definitions = new BrickDefinitions();

export default definitions;
