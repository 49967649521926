/** Field definition for touchpoints. Also called condition.
 * Page_url for a landingpage for instance.
 */
import {cleanKey, isEmpty} from '../util';
import {T} from '@dnx/core';

export class TouchpointFieldDefinition {
  /** parent_subtype => [ conditionid : {label,options,field} ] */
  constructor() {
    this.typeMap = new Map();
  }

  initialize(source) {
    const all = this.typeMap;
    all.clear();
    for (const [, list] of Object.entries(source)) {
      for (const item of list) {
        const {
          id /*=condition id */,
          touchpointType,
          touchpointSubType,
          valueType,
          field,
          name,
          translation,
          isRequired,
          isVisible,
          defaultValue,
          optionValue,
          optionName,
          optionTranslation,
        } = item;
        const key = cleanKey(`${touchpointType}_${touchpointSubType || ''}`);
        const fieldset = all.get(key) || new Map();

        const definition = fieldset.get(id) || {
          id,
          field,
          valueType,
          options: [],
          isVisible,
          display: 'XInput',
          required: isRequired,
          default: defaultValue,
          name: isEmpty(name) ? field : name,
          translation: translation,
        };

        if (optionValue) {
          definition.options.push({
            value: optionValue,
            label: optionTranslation ? T(optionTranslation) : isEmpty(optionName) ? optionValue : optionName,
          });
        }

        if (valueType === 'integer') {
          definition.display = 'integer';
        }

        if (~~definition.options.length > 1) {
          definition.display = 'XSelect';
        }

        fieldset.set(id, definition);

        all.set(key, fieldset);
      }
    }
  }

  /** Return a map (field => def) for this brick */
  getFieldMap(brick) {
    return [...this.getFields(brick)].reduce((map, def) => map.set(def.id, def), new Map());
  }

  /** Get custom field definitions for this parentType/subType (like page_url for web) */
  *getFields(brick) {
    const all = this.typeMap;

    let fields = all.get(cleanKey(`${brick.parentType}_`));
    if (fields) {
      for (const field of fields.values()) {
        yield field;
      }
    }

    fields = all.get(cleanKey(`${brick.parentType}_${brick.subType}`));
    if (fields) {
      for (const field of fields.values()) {
        yield field;
      }
    }
  }
}

const singleton = new TouchpointFieldDefinition();

export default singleton;
