var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from '../../app/api-generated/journey';
import { isEmpty, $can, addSuccessToast, addErrorToast, T } from "@dnx/core";
import useConfirmStore from '../store/confirmStore';
class ParentChildService {
    /**
     * Returns true when the journey can be disconnected from its parent
     * @return {boolean}
     */
    canDisconnectFromParent(journey) {
        if (isEmpty(journey.id))
            return false;
        if (isEmpty(journey.parentId))
            return false;
        return $can.create.JourneyComposerParentChild;
    }
    /**
     * Returns true when the journey can have children be created
     * @return {boolean}
     */
    canCreateChildren(journey) {
        if (isEmpty(journey.id))
            return false;
        if (!isEmpty(journey.parentId))
            return false;
        return $can.create.JourneyComposerParentChild;
    }
    /**
     * Create children for all subdivisions the current user has access to based on the current journey
     * @return {Promise<boolean>}
     */
    createChildren(journey) {
        return __awaiter(this, void 0, void 0, function* () {
            const confirmStore = useConfirmStore();
            const didConfirm = yield confirmStore.prompt(T('JOURNEY_COMPOSER_CREATE_CHILDREN_TITLE'), T('JOURNEY_COMPOSER_CREATE_CHILDREN_BODY'), 'information', {
                denyButton: confirmStore.defaultButton.cancel,
                confirmButton: Object.assign(Object.assign({}, confirmStore.defaultButton.yes), { icon: 'unlink' }),
            });
            if (!didConfirm)
                return false;
            const res = yield api.createChildJourneys(journey.id);
            if (!res.success) {
                addErrorToast(T('JOURNEY_COMPOSER_CREATE_CHILDREN_FAILED'));
                return false;
            }
            addSuccessToast(T('JOURNEY_COMPOSER_CREATE_CHILDREN_SUCCESS'));
            return true;
        });
    }
    /**
     * Stop the inheritance tree from the parent journey starting from this journey, this journey will now become the root of its own tree
     * @return {Promise<boolean>} Operation succeeded
     */
    disconnectFromParent(journey) {
        return __awaiter(this, void 0, void 0, function* () {
            const confirmStore = useConfirmStore();
            const didConfirm = yield confirmStore.prompt(T('JOURNEY_COMPOSER_DISCONNECT_FROM_PARENT_TITLE'), T('JOURNEY_COMPOSER_DISCONNECT_FROM_PARENT_BODY'), 'warning', {
                denyButton: confirmStore.defaultButton.cancel,
                confirmButton: Object.assign(Object.assign({}, confirmStore.defaultButton.yes), { buttonStyle: 'primary-warning', icon: 'unlink', name: T('JOURNEY_COMPOSER_DISCONNECT_FROM_PARENT_OK_BUTTON') }),
            });
            if (!didConfirm)
                return false;
            const res = yield api.disconnectFromParent(journey.id);
            if (!res.success) {
                addErrorToast(T('JOURNEY_COMPOSER_DISCONNECT_FROM_PARENT_FAILED'));
                return false;
            }
            addSuccessToast(T('JOURNEY_COMPOSER_DISCONNECT_FROM_PARENT_SUCCESS'));
            return true;
        });
    }
}
const parentChildService = new ParentChildService();
// type ParentChildService = ReturnType<typeof useParentChildService>;
// export type { ParentChildService }
export { parentChildService };
