var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
/**
 * Controller for reviews of journeys.
 */
class Review {
    constructor() {
        this.paths = Object.freeze({
            listForJourney: (journeyid) => `api/journeyreviews/journey/${encodeURIComponent(journeyid)}`,
            listOpenForSelf: () => `api/journeyreviews/reviews/self/open`,
            listPossibleReviewersForJourney: (journeyid) => `api/journeyreviews/possiblereviewers/${encodeURIComponent(journeyid)}`,
            getSettingsForJourney: (journeyid) => `api/journeyreviews/settings/${encodeURIComponent(journeyid)}`,
            requestReview: () => `api/journeyreviews/request`,
            saveReview: () => `api/journeyreviews/review`,
            approveOpenReviews: (id) => {
                const __query = new QueryStringBuilder().add("id", id);
                return `api/journeyreviews/review/bypass${__query.toString()}`;
            },
        });
    }
    /**
     * Review.ListForJourney "GET api/journeyreviews/journey/{journeyId}"
     *
     * List all open reviews for the given journeys current draft revision
     * @param {Guid} journeyid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ReviewModel[]>>} List of all reviews for the given journey
     */
    listForJourney(journeyid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/journey/${journeyid === undefined || journeyid === null ? "" : encodeURIComponent(journeyid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.ListOpenForSelf "GET api/journeyreviews/reviews/self/open"
     *
     * List all open reviews for the current user
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ReviewModel[]>>} List of all reviews yet to be performed
     */
    listOpenForSelf(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/reviews/self/open`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.ListPossibleReviewersForJourney "GET api/journeyreviews/possiblereviewers/{journeyId}"
     *
     * List all users allowed to review the given journey
     * @param {Guid} journeyid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<JourneyReviewer>>} List of users containing id and name
     */
    listPossibleReviewersForJourney(journeyid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/possiblereviewers/${journeyid === undefined || journeyid === null ? "" : encodeURIComponent(journeyid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.GetSettingsForJourney "GET api/journeyreviews/settings/{journeyId}"
     *
     * Read the configured review settings for the current draft for the given journey
     * Response includes the minimum amount of approvals required for the journey to be publishable
     * @param {Guid} journeyid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getSettingsForJourney(journeyid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/settings/${journeyid === undefined || journeyid === null ? "" : encodeURIComponent(journeyid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.RequestReview "POST api/journeyreviews/request"
     *
     * Start a new review, the current draft revision will be frozen and can't be altered anymore, mails will be send to all users marked as reviewer
     * @param {ReviewRequestModel} reviewrequestmodel
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>} Empty response
     */
    requestReview(reviewrequestmodel, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/request`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, reviewrequestmodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.SaveReview "POST api/journeyreviews/review"
     *
     * Submit the current users verdict for the current draft in 'review' state for the given journeyId
     * May be called multiple times to change verdict (eg. from Rejected to Accepted)
     * @param {ReviewModel} reviewmodel
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>} Empty response
     */
    saveReview(reviewmodel, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeyreviews/review`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, reviewmodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Review.ApproveOpenReviews "POST api/journeyreviews/review/bypass"
     *
     * Bypass review by approving all open reviews for given revisionId.
     * Used for testing purposes.
     * @param {number} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    approveOpenReviews(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("id", id);
            let path = `api/journeyreviews/review/bypass${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const review = new Review();
export { review as default, Review };
