import {hydrate, cleanKey, isEmpty} from '../util';

/** Add a type hierarchy (parenttype, subtype, variation) to the definition.  */
export default class TypeHierarchy {
  constructor(options) {
    this.settings = new Map();
    this.parse(options);
  }

  parse(data) {
    if (!Array.isArray(data)) {
      console.error('Tried to initialize types with a non-array');
      return;
    }

    for (const type of data) {
      hydrate(type);

      if (!this.settings.has(type.parentType)) {
        const parentMap = new Map();
        parentMap.icon = type.config?.icon || 'information-circle';
        parentMap.name = type.parentType;
        parentMap.translation = type.config?.translationParent;

        this.settings.set(type.parentType, parentMap);
      }
      const st = this.settings.get(type.parentType);

      // Set translation of parent to the first non-empty value
      if (isEmpty(st.translation) && !isEmpty(type.config?.translationParent))
        st.translation = type.config?.translationParent;

      if (st.has(cleanKey(type.name))) throw new Error(`Duplicate typename: ${cleanKey(type.name)}`);

      type.icon = type.config?.icon;
      type.translation = type.config?.translation || undefined;
      const {...typedef} = type;

      st.set(cleanKey(type.name), typedef);
    }
  }

  getTouchId(parent, subtype) {
    const typemap = this.settings.get(parent);
    const typedef = typemap?.get(cleanKey(subtype));
    return typedef?.id;
  }

  findByTouchId(id) {
    for (const typemap of this.settings.values())
      for (const typedef of typemap.values()) if (typedef.id === id) return typedef;

    console.warn(`Hierarchy: ${id} not found`);
  }

  *findByName(name) {
    const key = cleanKey(name);
    for (const [parentType, typemap] of this.settings) {
      if (cleanKey(parentType) === key) yield typemap;

      for (const typedef of typemap.values()) {
        if (cleanKey(typedef.name) === key) yield typedef;

        for (const variation in typedef.variation) {
          if (cleanKey(variation.name) === key) yield variation;
        }
      }
    }
  }

  /** Return icon/name/translation for all levels. */
  names(parentType, subType, variation) {
    const p = this.get(parentType);
    const t = this.get(parentType, subType);
    const v = this.get(parentType, subType, variation);

    return {
      parentType: p.level === 1 ? p : undefined,
      subType: t.level === 2 ? t : undefined,
      variant: v.level === 3 ? v : undefined,
    };
  }

  /** Return icon/name/translation/level for this item. 
      It will return most specific match
    */
  get(parentType, subType = undefined, variation = undefined) {
    const result = {icon: undefined, name: undefined, level: 0};
    let elm = this.settings.get(parentType);
    if (!elm) return result;
    result.level++;
    if (elm.icon) result.icon = elm.icon;
    if (elm.name) result.name = elm.name;
    if (!isEmpty(elm.translation)) result.translation = elm.translation;

    elm = elm.get(cleanKey(subType));
    if (!elm) return result;
    result.level++;
    if (elm.icon) result.icon = elm.icon;
    if (elm.name) result.name = elm.name;
    result.translation = elm.translation;

    for (const v of elm.variation) {
      if (v.name === variation) {
        result.level++;
        if (v.icon) result.icon = v.icon;
        if (v.name) result.name = v.name;
        result.translation = v.translation;
      }
    }
    return result;
  }

  // Return options for given type.
  options(parentType, subType = undefined) {
    switch (arguments.length) {
      case 1:
        return asOptions(this.settings);
      case 2:
        return isEmpty(parentType) ? undefined : asOptions(this.settings.get(parentType));
      case 3:
        return isEmpty(parentType) || isEmpty(subType)
          ? undefined
          : asOptions(this.settings.get(parentType).get(cleanKey(subType)));
    }
  }
}

export function asOptions(arr) {
  const result = [];
  if (arr == undefined) {
    return result;
  }

  if (arr instanceof Map) {
    for (const [key, value] of arr) {
      if (value.name) {
        result.push({
          label: value.name,
          value: value.name,
          translation: value.translation,
        });
      } else {
        result.push({label: key, value: key});
      }
    }
    return result;
  }

  if (Array.isArray(arr)) {
    if (arr[0] && arr[0].name)
      return arr.map(obj => ({
        label: obj.name,
        value: obj.name,
        translation: obj.translation,
      }));

    return arr.map(obj => ({label: obj, value: obj}));
  }

  if (arr.variation) {
    return asOptions(arr.variation);
  }
}
