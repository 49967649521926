var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class JourneyBrick {
    constructor() {
        this.paths = Object.freeze({
            getDefinitions: () => `api/journeybricks/definitions`,
            getTouchpointTypes: () => `api/journeybricks/touchpointTypes`,
            getCustomFields: (touchpointtype) => `api/journeybricks/customFields/${encodeURIComponent(touchpointtype)}`,
            getGoalTypes: () => `api/journeybricks/goalbrick/goaltypes`,
            getTimeUnits: () => `api/journeybricks/goalbrick/timeunits`,
            getTouchpointDelayDateColumns: () => `api/journeybricks/touchpoint/delayDateColumns`,
        });
    }
    /**
     * JourneyBrick.GetDefinitions "GET api/journeybricks/definitions"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getDefinitions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/definitions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * JourneyBrick.GetTouchpointTypes "GET api/journeybricks/touchpointTypes"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<TouchpointTypesSummary>>}
     */
    getTouchpointTypes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/touchpointTypes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * JourneyBrick.GetCustomFields "GET api/journeybricks/customFields/{touchpointType}"
     * @param {string} touchpointtype
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomFieldModel[]>>}
     */
    getCustomFields(touchpointtype, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/customFields/${touchpointtype === undefined || touchpointtype === null ? "" : encodeURIComponent(touchpointtype)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * JourneyBrick.GetGoalTypes "GET api/journeybricks/goalbrick/goaltypes"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<GoalTypeModel[]>>}
     */
    getGoalTypes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/goalbrick/goaltypes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * JourneyBrick.GetTimeUnits "GET api/journeybricks/goalbrick/timeunits"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getTimeUnits(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/goalbrick/timeunits`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * JourneyBrick.GetTouchpointDelayDateColumns "GET api/journeybricks/touchpoint/delayDateColumns"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<string[]>>}
     */
    getTouchpointDelayDateColumns(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeybricks/touchpoint/delayDateColumns`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const journeyBrick = new JourneyBrick();
export { journeyBrick as default, JourneyBrick };
