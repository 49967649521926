import {isEmpty} from '../util';
import lookup from '../services/lookup';
import Validation, {ValidationEntry} from '../services/validation';
import {useJourneyStore} from '../store/journey';
import {T, Uuid} from '@dnx/core';

/**
 * @typedef Validator
 *
 * @property {function(checkpoint: *): *} [initialize]
 * @property {function(checkpoint: *): ValidationEntry | undefined} validate
 * @property {function(checkpoint: *): *} update
 * @property {function(checkpoint: *): *} parse
 */

/**
 * Validate filter conditions of field containing filter condition groups.
 *
 * @param {*} field
 * @param {string} [name]
 * @param {Object} [context]
 * @param {'journey'|'brick'='brick'} [group]
 */
export function validateFilterConditions(field, name = undefined, context, group = 'brick') {
  const issues = [];

  if (isEmpty(field)) {
    issues.push(
      Validation.error(
        T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
          name: name || T('CHECKPOINTS_FILTER'),
        }),
        group,
        context
      )
    );
  } else {
    const conditions = field.reduce((all, grp) => {
      return all.concat(grp.conditions.map(item => ({...item, group: grp.name})));
    }, []);

    for (const {field, operator, value} of conditions) {
      if (isEmpty(field)) {
        issues.push(
          Validation.error(
            T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
              name: name || T('CHECKPOINTS_FILTER'),
            }),
            group,
            context
          )
        );
      } else if (isEmpty(operator)) {
        issues.push(
          Validation.error(
            T('CHECKPOINTS_FIELD_HAS_NO_OPERATOR', {
              name: name || T('CHECKPOINTS_FILTER'),
              field,
            }),
            group,
            context
          )
        );
      } else if (isEmpty(value) || (Array.isArray(value) && value.length && isEmpty(value[0]))) {
        if (group == 'journey') {
          issues.push(Validation.error(T('JOURNEY_SETTINGS_FILTER_HAS_NO_VALUE'), group, context));
        } else {
          issues.push(
            Validation.error(
              T('CHECKPOINTS_FIELD_HAS_NO_VALUE', {
                name: name || T('CHECKPOINTS_FILTER'),
                field,
              }),
              group,
              context
            )
          );
        }
      }
    }
  }

  return issues.length > 0 ? issues : Validation.success();
}

/** Take field containing field condition groups and return updated filter field
 *
 * "[
 *   {name:g1,conditions:[{field:a,operator:equals,value:1},{field:a,operator:equals,value:2},{field:a,operator:notEquals,value:3}]},
 *   {name:g2,conditions:[{field:b,operator:notEquals,value:3},
 * ]"
 *    =>
 * "[[field:a,operator:equals,value:[1,2],group:g1,field:a,operator:notEquals,value:3,group:g1],[field:b,operator:notEquals,value:3,group:g2]]"
 */
export function updateFilterConditions(field) {
  if (isEmpty(field)) return undefined;

  return field
    .map(grp => {
      return grp.conditions.reduce((agg, condition) => {
        if (isEmpty(condition) || (isEmpty(condition.field) && isEmpty(condition.operator) && isEmpty(condition.value)))
          return agg;

        let group = agg.find(x => x.field === condition.field && x.operator === condition.operator);

        if (!group) {
          group = {
            field: condition.field,
            operator: condition.operator,
            value: [],
            group: grp.id,
          };
          agg.push(group);
        }
        group.value = Array.isArray(condition.value) ? condition.value : [condition.value];
        return agg;
      }, []);
    })
    .filter(group => !!group.length);
}

/** Parse a field containing filter conditions returning a grouped filter.
 *
 * "[[field:a,operator:equals,value:[1,2],group:g1,field:a,operator:notEquals,value:3,group:g1],[field:b,operator:notEquals,value:3,group:g2]]"
 *    =>
 * "[
 *   {name:g1,conditions:[{field:a,operator:equals,value:1},{field:a,operator:equals,value:2},{field:a,operator:notEquals,value:3}]},
 *   {name:g2,conditions:[{field:b,operator:notEquals,value:3},
 * ]"
 */
export function parseFilterConditions(field) {
  if (isEmpty(field)) return [];
  const all = field.flatMap(list => {
    return list.flatMap(conditions =>
      Array.isArray(conditions.value)
        ? {
            field: conditions.field,
            operator: conditions.operator,
            value: Array.isArray(conditions.value[0]) ? conditions.value[0] : conditions.value,
            group: conditions.group,
            id: conditions.id || Uuid.NewUuid().toString(),
          }
        : {
            field: conditions.field,
            operator: conditions.operator,
            value: [conditions.value],
            group: conditions.group,
          }
    );
  });

  const groups = all.reduce((g, condition) => {
    const id = condition.group;
    delete condition.group;
    if (!g.has(id)) g.set(id, [condition]);
    else g.get(id).push(condition);
    return g;
  }, new Map());

  return Array.from(groups.entries()).map(([id, conditions]) => ({
    id,
    conditions,
  }));
}

export const Types = {
  audiencededuplication: 'dedupBase',
  delay: 'offset',
  exclusionjourney: 'filterExclusionCheckpoint',
  filter: 'filter',
  targetdeduplication: 'dedupTarget',
  timer: 'timer',
  verification: 'verification',
};

const timeUnits = {
  seconds: {
    id: 's',
    aliases: ['seconds'],
    label: 'Seconds',
    toSeconds: value => value,
    fromSeconds: value => value,
  },
  minutes: {
    id: 'm',
    aliases: ['minutes'],
    label: 'Minutes',
    toSeconds: value => value * 60,
    fromSeconds: value => value / 60,
  },
  hours: {
    id: 'h',
    aliases: ['hours'],
    label: 'Hours',
    toSeconds: value => value * 60 * 60,
    fromSeconds: value => value / 60 / 60,
  },
  days: {
    id: 'd',
    aliases: ['days', 'daily'],
    label: 'Days',
    toSeconds: value => value * 60 * 60 * 24,
    fromSeconds: value => value / 60 / 60 / 24,
  },
  weeks: {
    id: 'w',
    aliases: ['weeks', 'weekly'],
    label: 'Weeks',
    toSeconds: value => value * 60 * 60 * 24 * 7,
    fromSeconds: value => value / 60 / 60 / 24 / 7,
  },
  workDays: {
    id: 'wD',
    aliases: ['workdays'],
    label: 'WorkDays',
    toSeconds: value => value * 60 * 60 * 8,
    fromSeconds: value => value / 60 / 60 / 8,
  },
  workWeeks: {
    id: 'Ww',
    aliases: ['workweeks'],
    label: 'WorkWeeks',
    toSeconds: value => value * 60 * 60 * 8 * 5,
    fromSeconds: value => value / 60 / 60 / 8 / 5,
  },

  find(unit) {
    if (isEmpty(unit)) return undefined;

    const eligible = [this.seconds, this.minutes, this.hours, this.days, this.weeks, this.workDays, this.workWeeks];

    //Assume aliases are always lowercased
    unit = unit.toLowerCase();
    return eligible.find(e => e.id.toLowerCase() === unit || e.aliases.includes(unit));
  },
};

function convertToSeconds(sourceUnit, value) {
  const unit = timeUnits.find(sourceUnit);
  if (unit) {
    return unit.toSeconds(value);
  }
  return value;
}

function convertFromSeconds(targetUnit, value) {
  const unit = timeUnits.find(targetUnit);
  if (unit) {
    return unit.fromSeconds(value);
  }
  return value;
}

export const checkpointOrder = [
  Types.delay,
  Types.timer,
  Types.targetdeduplication,
  Types.audiencededuplication,
  Types.filter,
  Types.verification,
  Types.exclusionjourney,
];

/** Sort function for checkpoints  */
export function sortCheckpoints(l, r) {
  return l === r ? 0 : checkpointOrder.indexOf(l) < checkpointOrder.indexOf(r) ? -1 : 1;
}

const isValidTime = value =>
  /[00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23]:[0-5][0-9]:[0-5][0-9]/.test(value || '');
const isPositive = value =>
  Number.isFinite(value) ? +value > 0 : value instanceof String ? parseInt(value) > 0 : false;
const isInRange = (value, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) =>
  Number.isFinite(value) && value >= min && value <= max;

/**
 * @type { Record.<string, Validator> }
 */
const validators = {
  [Types.targetdeduplication]: {
    validate(checkpoint) {
      checkpoint.fields.forEach(field => {
        if (!field.value) {
          return Validation.error(
            T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
              name: Definitions[Types.targetdeduplication].name,
            }),
            'brick'
          );
        }
      });
      if ((checkpoint.termUnit || checkpoint.termValue) && (!checkpoint.termUnit || !checkpoint.termValue)) {
        return Validation.error(
          T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
            name: Definitions[Types.targetdeduplication].name,
          }),
          'brick'
        );
      }

      return Validation.success();
    },
    update(checkpoint) {
      if (isEmpty(checkpoint)) return undefined;

      const {
        fields, //Required
        termUnit,
        termValue,
      } = checkpoint;

      //Require at least a single value
      if (isEmpty(fields) || isEmpty(fields[0].value)) return undefined;

      const termModel = {};
      if (!isEmpty(termUnit) && !isEmpty(termValue)) {
        termModel.unit = termUnit;
        termModel.seconds = convertToSeconds(termUnit, termValue);
      }

      return {
        //Take an array of values
        fields: fields.filter(f => f.value).map(f => f.value),
        term: !isEmpty(termModel) && termModel,
      };
    },

    parse(model) {
      if (isEmpty(model)) return undefined;

      const termModel = !isEmpty(model.term) && typeof model.term === 'object' ? model.term : {};
      const termUnit = !isEmpty(termModel.unit) ? termModel.unit : undefined;
      const termValue = !isEmpty(termModel.seconds) ? termModel.seconds : undefined;

      return {
        fields: model.fields.map(value => ({value: value})),
        termUnit: termUnit,
        termValue: convertFromSeconds(termUnit, termValue),
      };
    },
  },
  [Types.audiencededuplication]: {
    validate(checkpoint) {
      return validateFilterConditions(checkpoint?.groups, Definitions[Types.audiencededuplication].name);
    },
    update(checkpoint) {
      return isEmpty(checkpoint?.groups)
        ? undefined
        : {
            product: checkpoint.productCheck,
            conditions: updateFilterConditions(checkpoint.groups),
          };
    },

    parse(model) {
      return isEmpty(model)
        ? undefined
        : {
            productCheck: model.product,
            groups: parseFilterConditions(model.conditions),
          };
    },
  },

  [Types.delay]: {
    validate(checkpoint) {
      const {dateField, termValue, termUnit, orderSelected} = checkpoint;

      const hasTerm = !isEmpty(termValue) || !isEmpty(termUnit);

      if (isEmpty(dateField) && !hasTerm) {
        return Validation.error(
          T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
            name: Definitions[Types.delay].name,
          }),
          'brick'
        );
      }

      if ((isEmpty(dateField) && !isPositive(termValue)) || (isEmpty(termValue) && !isEmpty(termUnit)))
        return Validation.error(
          T('CHECKPOINT_DELAY_VALUE_NOT_SELECTED', {
            name: Definitions[Types.delay].name,
          }),
          'brick'
        );

      if ((isEmpty(dateField) && isEmpty(termUnit)) || (isEmpty(termUnit) && !isEmpty(termValue)))
        return Validation.error(
          T('CHECKPOINT_DELAY_UNIT_NOT_SELECTED', {
            name: Definitions[Types.delay].name,
          }),
          'brick'
        );

      if (!isEmpty(dateField) && hasTerm && isEmpty(orderSelected)) {
        return Validation.error(
          T('CHECKPOINT_DELAY_MOMENT_NOT_SELECTED', {
            name: Definitions[Types.delay].name,
          }),
          'brick'
        );
      }

      return Validation.success();
    },
    update(checkpoint) {
      if (isEmpty(checkpoint)) {
        return undefined;
      }

      const {dateField, termValue, termUnit, orderSelected} = checkpoint;

      if (isEmpty(dateField) && isEmpty(termValue) && isEmpty(termUnit)) {
        return undefined;
      }

      let seconds = undefined;
      if (Number.isFinite(termValue)) {
        seconds = convertToSeconds(termUnit, termValue);
        if (orderSelected === 'Before') {
          seconds = -seconds;
        }
      }

      return {
        dateField: dateField,
        seconds: seconds,
        unit: termUnit,
      };
    },

    parse(model) {
      if (isEmpty(model)) return undefined;

      const {dateField, seconds, unit} = model;

      let value = undefined;
      let order = undefined;

      if (Number.isFinite(seconds)) {
        value = convertFromSeconds(unit, Math.abs(seconds || 0));
        order = seconds < 0 ? 'Before' : 'After'; //value is always positive due to us transforming it to an absolute number
      }

      return {
        dateField: dateField,
        termValue: value,
        termUnit: unit,
        orderSelected: order,
      };
    },
  },

  [Types.filter]: {
    validate(checkpoint) {
      return validateFilterConditions(checkpoint?.groups, Definitions[Types.filter].name);
    },
    update(checkpoint) {
      return isEmpty(checkpoint?.groups)
        ? undefined
        : {
            conditions: updateFilterConditions(checkpoint?.groups),
          };
    },

    parse(model) {
      return isEmpty(model?.conditions)
        ? undefined
        : {
            groups: parseFilterConditions(model?.conditions),
          };
    },
  },

  [Types.timer]: {
    validate(checkpoint) {
      if (isEmpty(checkpoint)) return Validation.success();
      const configuration = validators[Types.timer].update(checkpoint);

      if (!configuration.scheduleType)
        return Validation.error(
          T('CHECKPOINTS_FILTER_HAS_NO_DATA', {
            name: Definitions[Types.timer].name,
          }),
          'brick'
        );

      if (isEmpty(configuration)) return Validation.success();
      if (configuration.scheduleType === 'once') {
        if (!isValidTime(configuration.time)) return Validation.error(T('CHECKPOINT_TIMER_NO_TIME_SELECTED'), 'brick');
        if (isEmpty(configuration.date)) return Validation.error(T('CHECKPOINT_TIMER_NO_STARTDATE_SELECTED'), 'brick');
      }
      if (configuration.scheduleType === 'recurring') {
        if (isEmpty(configuration.UI?.frequencyRecurrenceUnit))
          return Validation.error(T('CHECKPOINT_TIMER_RECURRING_NO_FREQUENCY_SELECTED'), 'brick');
        if (isEmpty(configuration.startDate))
          return Validation.error(T('CHECKPOINT_TIMER_RECURRING_NO_STARTDATE_SELECTED'), 'brick');

        if (configuration.UI?.frequencyRecurrenceUnit === 'weekly') {
          //if (isEmpty(configuration.weekdays)) return Validation.error("Recurring weekly timer needs weekdays");
          if (isEmpty(configuration.dailyFrequency))
            return Validation.error(T('CHECKPOINT_TIMER_RECURRING_WEEKLY_TIMER_FREQUENCY'), 'brick');
          if (configuration.dailyFrequency !== 'once')
            if (!isPositive(configuration.dailyRecursInSeconds))
              return Validation.error(T('CHECKPOINT_TIMER_RECURRING_WEEKLY_TIMER_INTERVAL'), 'brick');
        }

        if (isEmpty(configuration.dailyFrequency))
          return Validation.error(T('CHECKPOINT_TIMER_RECURRING_DAILY_TIMER_FREQUENCY'), 'brick');
        if (!isValidTime(configuration.dailyStartTime))
          return Validation.error(T('CHECKPOINT_TIMER_RECURRING_DAILY_TIMER_STARTTIME'), 'brick');
        if (!isValidTime(configuration.dailyEndTime))
          return Validation.error(T('CHECKPOINT_TIMER_RECURRING_DAILY_TIMER_ENDTIME'), 'brick');
        if (configuration.dailyFrequency !== 'once') {
          if (!isPositive(configuration.dailyRecursInSeconds))
            return Validation.error(T('CHECKPOINT_TIMER_RECURRING_DAILY_TIMER_INTERVAL'), 'brick');
          if (isEmpty(configuration.UI?.dailyFrequencyRecurrenceUnit))
            return Validation.error(T('CHECKPOINT_TIMER_RECURRING_DAILY_TIMER_INTERVAL_UNIT'), 'brick');
        }
      }
    },
    update(checkpoint) {
      if (isEmpty(checkpoint)) return undefined;

      const serializeTime = timeObj => {
        if (isEmpty(timeObj)) return undefined;

        const h = timeObj.hours.toString().padStart(2, '0');
        const m = timeObj.minutes.toString().padStart(2, '0');
        const s = timeObj.seconds.toString().padStart(2, '0');
        return `${h}:${m}:${s}`;
      };

      const configuration = {
        scheduleType: checkpoint.schema,
      };

      if (configuration.scheduleType === 'once') {
        configuration.date = checkpoint.durationStartDate;
        configuration.time = serializeTime(checkpoint.dailyInterval.dailyFrequencyStartTime);
      }

      if (configuration.scheduleType === 'recurring') {
        configuration.UI = {
          frequencyRecurrenceUnit: checkpoint.repetitionInterval,
          dailyFrequencyRecurrenceUnit: checkpoint.dailyInterval.dailyFrequencyUnit,
        };

        const intervalInSeconds = timeUnits
          .find(checkpoint.repetitionInterval)
          ?.toSeconds(checkpoint.intervalFrequency.frequencyNumber);
        configuration.recursInDays = timeUnits.days.fromSeconds(intervalInSeconds);

        configuration.weekdays = checkpoint.intervalFrequency.weekDays;

        configuration.dailyFrequency = checkpoint.dailyInterval.frequencySettings;
        configuration.dailyStartTime = serializeTime(checkpoint.dailyInterval.dailyFrequencyStartTime);
        configuration.dailyEndTime = serializeTime(checkpoint.dailyInterval.dailyFrequencyEndTime);
        configuration.dailyRecursInSeconds = convertToSeconds(
          checkpoint.dailyInterval.dailyFrequencyUnit,
          checkpoint.dailyInterval.dailyFrequencyNumber
        );

        configuration.startDate = checkpoint.durationStartDate;
        configuration.endDate = checkpoint.durationEndDate;
      }

      return configuration;
    },

    parse(model) {
      if (isEmpty(model)) return undefined;

      const parseTime = timeString => {
        if (isEmpty(timeString)) return undefined;

        const [h, m, s] = timeString.split(':');
        return {
          hours: parseInt(h),
          minutes: parseInt(m),
          seconds: parseInt(s),
        };
      };

      return {
        schema: model.scheduleType,
        repetitionInterval: model.UI?.frequencyRecurrenceUnit,
        dailyInterval: {
          dailyFrequencyUnit: model.UI?.dailyFrequencyRecurrenceUnit,
          dailyFrequencyNumber: convertFromSeconds(model.UI?.dailyFrequencyRecurrenceUnit, model.dailyRecursInSeconds),
          frequencySettings: model.dailyFrequency,
          dailyFrequencyStartTime: parseTime(model.scheduleType === 'once' ? model.time : model.dailyStartTime) || {
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
          dailyFrequencyEndTime: parseTime(model.dailyEndTime) || {
            hours: 23,
            minutes: 59,
            seconds: 59,
          },
        },
        intervalFrequency: {
          weekDays: model.weekdays,
          frequencyNumber: timeUnits
            .find(model.UI?.frequencyRecurrenceUnit)
            ?.fromSeconds(timeUnits.days.toSeconds(model.recursInDays)),
        },

        //When a scheduleType of once was configured, we'll be working with a singular date field named `date`
        durationStartDate: model.date || model.startDate,
        durationEndDate: model.date || model.endDate,
      };
    },
  },
  [Types.exclusionjourney]: {
    initialize(checkpoint) {
      // Exluded all conditions by default.
      const journey = useJourneyStore();
      if (isEmpty(checkpoint.excludedFilters)) {
        checkpoint.excludedFilters = journey.groups.map(grp => grp.id);
      }
    },
    validate(checkpoint) {
      if (isEmpty(checkpoint.excludedFilters)) {
        return Validation.error(
          T('CHECKPOINT_EXCLUSION_JOURNEY_NO_GROUP', {
            name: Definitions[Types.exclusionjourney].name,
          }),
          'brick'
        );
      }

      return Validation.success();
    },
    update(checkpoint) {
      return {excludedFilters: checkpoint.excludedFilters || []};
    },
    parse(model) {
      return {excludedFilters: model?.excludedFilters || []};
    },
  },
  [Types.verification]: {
    validate(checkpoint) {
      return isInRange(checkpoint.percentage, 1, 100)
        ? Validation.success()
        : Validation.error(
            T('CHECPOINT_PERCENTANGE_OUT_OF_RANGE', {
              name: Definitions[Types.verification].name,
            }),
            'brick'
          );
    },
    update(checkpoint) {
      if (!Number.isFinite(checkpoint.percentage)) {
        return;
      }
      return {percentage: checkpoint.percentage};
    },
    parse(model) {
      return {
        percentage: Number.isFinite(model?.percentage) ? model?.percentage : '',
      };
    },
  },
};

//DEV: Should be in database!
export const FieldConditions = {};

export class FieldCondition {
  static initialize() {
    FieldConditions['filter'] = new FieldCondition(lookup['audience-filter-field-types'], ['Audience']);
    FieldConditions['dedupBase'] = new FieldCondition(lookup['condition-datasets']);
    FieldConditions['journey'] = new FieldCondition(lookup['condition-datasets']);
  }

  constructor(dataSource, excludeTypes) {
    this.typeMap = dataSource
      ? Object.values(dataSource)
          .flat()
          .reduce((map, {type, label, value, id, isDefault, isDropDown, valueType, isMultiDropDown}) => {
            const mapping = map.get(type) || {
              label,
              valueType,
              options: [],
              display: 'XInput',
              operators: lookup['field-condition-operator'],
            };

            mapping.options.push({value: id, label: value});
            if (isDefault) mapping.default = id ? (Array.isArray(id) ? id : [id]) : [];

            if (isDropDown) mapping.display = 'XSelect';
            if (isMultiDropDown) mapping.display = 'XMultiSelect';

            map.set(type, mapping);
            return map;
          }, new Map())
      : new Map();

    //Temporary, remove once audiences are removed from audience-filter-field-types datasource
    this.exclude = excludeTypes;
  }

  component(instance) {
    return this.typeMap.get(instance?.field)?.display;
  }

  get fieldOptions() {
    const options = [...this.typeMap.keys()].filter(item => isEmpty(this.exclude) || this.exclude?.indexOf(item) < 0);
    return options.map(name => ({
      value: name,
      label: this.typeMap.get(name).label,
    }));
  }

  create() {
    return {
      field: undefined,
      operator: undefined,
      value: [],
    };
  }

  get(instance, name) {
    switch (name) {
      case 'field':
      case 'type':
        return instance?.field;
      case 'operator':
        return instance?.operator;
      case 'value':
      default: {
        const searchValue = Array.isArray(instance?.value)
          ? instance.value.length > 0
            ? instance.value[0]
            : undefined
          : instance?.value;
        return (this.typeMap.get(instance?.field)?.options || []).find(itm => itm.value === searchValue)?.label;
      }
    }
  }

  set(instance, what, value) {
    switch (what) {
      case 'field':
        if (value !== instance.field) {
          const def = this.typeMap.get(value);
          instance.field = value;
          instance.value = [...(def?.default ?? [])];
          instance.operator = def?.operators[0].value || undefined;
        }
        break;
      case 'value':
        instance[what] = Array.isArray(value) ? value : [value];
        break;
      default:
        instance[what] = value;
        break;
    }
  }

  options(instance, name) {
    switch (name) {
      case 'field':
      case 'type':
        return this.fieldOptions;
      case 'operator':
        return this.typeMap.get(instance?.field)?.operators || [];
      case 'value':
      default:
        return this.typeMap.get(instance?.field)?.options || [];
    }
  }
}

export default class CheckpointDefinition {
  constructor(type, icon, translation, config) {
    this.icon = icon;
    this.translation = translation;
    this.typeName = type;
    this.value = type;
    this.config = config || {};

    this.update = () => undefined;
    this.serialize = () => undefined;
    Object.assign(this, {...validators[type]});
  }

  get name() {
    return this.translation ? T(this.translation) : this.typeName;
  }

  create(model) {
    const checkpoint = {
      ...this.parse?.call(this, model),
      typeName: this.typeName,
    };

    if (typeof this.initialize === 'function') this.initialize(checkpoint);

    return checkpoint;
  }
}

export const Definitions = {
  [Types.delay]: new CheckpointDefinition(Types.delay, 'calender', 'JC_CHECKPOINT_DELAY'),
  [Types.timer]: new CheckpointDefinition(Types.timer, 'clock-hands', 'JC_CHECKPOINT_TIMER'),
  [Types.targetdeduplication]: new CheckpointDefinition(
    Types.targetdeduplication,
    'deduplication-target',
    'JC_CHECKPOINT_TARGETDEDUPLICATION'
  ),
  [Types.audiencededuplication]: new CheckpointDefinition(
    Types.audiencededuplication,
    'person',
    'JC_CHECKPOINT_AUDIENCEDEDUPLICATION'
  ),
  [Types.filter]: new CheckpointDefinition(Types.filter, 'sliders', 'JC_CHECKPOINT_FILTER'),
  [Types.verification]: new CheckpointDefinition(Types.verification, 'check-big', 'JC_CHECKPOINT_VERIFICATION'),
  [Types.exclusionjourney]: new CheckpointDefinition(Types.exclusionjourney, 'journey', 'JC_CHECKPOINT_EXCLUSION'),
};
