import { isEmpty } from '../util';
export function toTreeOptions(value) {
    var _a, _b, _c;
    if (isEmpty(value)) {
        return [];
    }
    if (typeof value === 'object') {
        value = [value];
    }
    if (typeof value === 'string' || !Array.isArray(value)) {
        throw new Error('Treeoptions expects an array or an object');
    }
    const result = [];
    for (const item of value) {
        const n = {
            id: (_a = item.id) !== null && _a !== void 0 ? _a : item.value,
            label: (_c = (_b = item.name) !== null && _b !== void 0 ? _b : item.label) !== null && _c !== void 0 ? _c : item.id,
            children: [],
        };
        item.children.forEach(child => n.children.push(...toTreeOptions(child)));
        result.push(n);
    }
    return result;
}
export default {
    // Add a new dataset to the lookup.
    add(name, data) {
        if (!Array.isArray(data) && isEmpty(data))
            return;
        if (Array.isArray(data) || !data._isMultiResultSet)
            this.addInternal(name, data);
        else {
            //FIXME: if data is multiresultset, the server provides names.
            // These might not match the requested name.
            // add('email-campaign', getCampaigns('email')) => {'email': [1,2], _multiResultSet:true})
            // For now: if it contains a single result, use the requestname.
            // Fix: register what endpoint delivers what names, or force inputname (getCampaigns('email-campaign'))
            const sets = Object.keys(data);
            const altName = sets.length < 3 ? name : undefined;
            for (const name of sets)
                if (name !== '_isMultiResultSet')
                    if (Array.isArray(data) || !isEmpty(data[name]))
                        this.addInternal(altName || name, data[name]);
        }
    },
    addInternal(name, data) {
        this[name] = data;
    },
};
