import {defineStore} from 'pinia';
import api from '../../app/api-generated/review';
import {useStore as useVuex} from 'vuex';
import {useJourneyStore} from './journey.ts';
import {cache} from '@dnx/core';
import {T} from '@dnx/core';
import eventBus, {Events} from '../services/event-bus';

//TODO: an api call to get all the remarks of the reviewer

//Purely used as indicator of whether or not the UI element is opened or not
const reviewStatus = {
  cannotRequestReview: 0, //Popup closed
  canRequestReview: 1, //Popup open
};

export const useReviewStore = defineStore('review', {
  state: () => {
    return {
      status: reviewStatus['cannotRequestReview'],
      reviewers: {},
      minimumReviewers: 3,
      selectedReviewerIds: {},
      remark: {},
      userAllowedToRequestReview: {},
      reviewRemark: {},
      reviewingJourneyMetaData: {},
    };
  },
  getters: {
    getReviewers() {
      return this.reviewers;
    },
    getSelectedReviewerIds: state => state.selectedReviewerIds,
  },
  actions: {
    async approveOpenReviews(revisionId) {
      const response = await api.approveOpenReviews(revisionId);
      return response.success;
    },
    async getListPossibleReviewersForJourney(journeyId) {
      this.reviewers[journeyId] = []; //Initialize empty

      const response = await cache.getFromApi('review.listPossibleReviewersForJourney', api, journeyId);
      if (!response) return;

      cache.save();

      // Format it to the output our selects want
      this.reviewers[journeyId] = response.map(item => ({
        value: item.id,
        label: item.name,
      }));
    },
    async getMinimumAmountOfReviewers(journeyId) {
      const response = await api.getSettingsForJourney(journeyId);

      if (response.success) {
        this.minimumReviewers = response.value.minimumApprovalCount;
      }
    },
    async requestReview(journeyId) {
      const store = useVuex();

      const response = await api.requestReview({
        journeyId,
        reviewerIds: this.selectedReviewerIds[journeyId],
        comment: this.remark[journeyId],
      });

      if (response.success) {
        this.status = reviewStatus['cannotRequestReview'];
        const journeyStore = useJourneyStore();
        journeyStore.status = 'PendingReview';

        store.dispatch(
          'notifications/triggerAlertMessage',
          {
            type: 'success',
            message: T('JOURNEY_REVIEW_REQUEST_SUCCESSFUL'),
          },
          {root: true}
        );

        eventBus.emit(Events.JOURNEY_UPDATED);
      } else {
        this.status = reviewStatus['cannotRequestReview'];

        store.dispatch(
          'notifications/triggerAlertMessage',
          {
            type: 'error',
            message: response.value?.message ?? response.message,
          },
          {root: true}
        );
      }

      // remove the journey id things from the state
    },
    async listForJourney(journeyId) {
      const store = useVuex();

      if (!journeyId) {
        throw new Error('Missing Journey Id');
      }

      const response = await api.listForJourney(journeyId);
      if (!response.success) {
        return;
      }

      const findUserInPendingReviewList = response.value.find(
        item => item.reviewerId === store.getters['login/userId']
      );

      if (findUserInPendingReviewList?.status === 'Open') {
        this.userAllowedToRequestReview[journeyId] = true;
        this.reviewingJourneyMetaData[journeyId] = findUserInPendingReviewList;
      } else {
        this.userAllowedToRequestReview[journeyId] = false;
      }
    },
    async saveReview(journeyId, status) {
      const store = useVuex();

      const response = await api.saveReview({
        ...this.reviewingJourneyMetaData[journeyId],
        status,
        comment: this.reviewRemark[journeyId] ?? null,
      });

      if (response.success) {
        this.userAllowedToRequestReview[journeyId] = false;
        store.dispatch(
          'notifications/triggerAlertMessage',
          {
            type: 'success',
            message: T('JOURNEY_REVIEW_SAVED'),
          },
          {root: true}
        );

        eventBus.emit(Events.JOURNEY_UPDATED);
      } else {
        store.dispatch(
          'notifications/triggerAlertMessage',
          {
            type: 'error',
            message: response.value?.message ?? response.message,
          },
          {root: true}
        );
      }
    },
  },
});
