// The datamodel service provides the import/export from the dbmodels to the store used models.

import {except, intersection, isEmpty} from '../util';
import factory from './brick-factory';
import Validation from '../services/validation';
import {validateFilterConditions} from '../types/checkpoint-definition';
import {DateTime} from 'luxon';
import {T, Uuid} from '@dnx/core';
import {useComposerStore} from '../store';

export class DataModelService {
  expandConfigs(value) {
    if (typeof value.config === 'string') value.config = JSON.parse(value.config);
    for (const connection of value.connections) {
      if (typeof connection.config === 'string') connection.config = JSON.parse(connection.config);
    }
    return value;
  }

  /** Unpack a journey by expaning all the configs.
   * {returns} A clone of the json with all properties expanded
   */
  unpack(json) {
    const journey = JSON.parse(JSON.stringify(json));

    // Set Journey Type to default
    if (journey.type === 'Standard') journey.type = '0';

    if (typeof journey.config === 'string') journey.config = JSON.parse(journey.config);

    // Upgrade filter definition by assigning group name & id.
    // Condition id becomes value
    journey.groups = (journey.filters || []).map((grp, idx) => {
      if (isEmpty(grp.id)) grp.id = Uuid.NewUuid().toString();
      if (isEmpty(grp.name)) grp.name = `${T('CHECKPOINT_FILTER_CONDITIONS_GROUP')} ${idx + 1}`;
      if (!Array.isArray(grp.conditions)) grp.conditions = [];

      grp.conditions.forEach(condition => {
        if (!isEmpty(condition.id) && !Object.hasOwn(condition, 'value')) {
          condition.value = condition.id;
        }
      });
      return grp;
    });

    const brickMap = {};
    for (const model of journey.bricks) {
      this.expandConfigs(model);
      const brick = factory.parse(model);
      brickMap[brick.id] = brick;
    }

    // Duplicate inbound side of connection to destination bricks. Saving only maintains outgoing.
    for (const brick of Object.values(brickMap)) {
      for (const connection of Object.values(brick.outgoing)) {
        const inbound = {...connection};
        const source = brickMap[connection.destinationBrickId];
        const destination = brickMap[connection.destinationBrickId];

        source.connections[connection.id] = inbound;
        destination.incoming[connection.id] = inbound;

        //Fixer upper our checkpoints, prevent no longer supported checkpoints from polluting our configuration
        const supportedCheckpoints = intersection(source.options('checkpoints'), destination.options('checkpoints'));
        const unsupportedCheckpoints = except(Object.keys(inbound.checkpoints), supportedCheckpoints);

        for (const checkpoint of unsupportedCheckpoints) {
          delete inbound.checkpoints[checkpoint];
        }
      }
    }

    journey.bricks = brickMap;

    return journey;
  }

  /** Validate a journey */
  validate(journey) {
    // Since validation has side effects (updates config,etc) journey validation happens here. Not in journey.js.
    Validation.reset();

    if (useComposerStore().identicalToPreviousRevision)
      Validation.critical(T('JOURNEY_IDENTICAL_VERSION'), 'journey', journey);

    if (isEmpty(journey?.name)) Validation.critical(T('JOURNEY_HAS_NO_NAME'), 'journey', journey);
    if (isEmpty(journey?.divisionId)) Validation.critical(T('JOURNEY_HAS_NO_DIVISION'), 'journey', journey);
    if (!journey?.startDate) Validation.critical(T('COMPOSER_CONFIRM_JOURNEY_STARTDATE_REQUIRED'), 'journey', journey);

    if (journey?.groups?.length) {
      validateFilterConditions(journey.groups, T('JOURNEY'), journey, 'journey');
    }

    for (const brick of Object.values(journey.bricks))
      try {
        Validation.context = brick;
        factory.validate(brick);
        Validation.context = undefined;
      } catch (e) {
        console.log(`${brick.name || brick.typeName} validation errored: ${e}`);
      }

    return Validation.reset();
  }
}

export default new DataModelService();
