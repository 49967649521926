import 'systemjs-webpack-interop/auto-public-path';

import {lifecycle} from './app.js';
import {routes} from './router.js';
import Overview from './pages/Overview.vue';
import {wire} from './overview';
import { acl } from '@dnx/core';

// singleSpa & mountParcel also in props
function createManifest() {
  const {name, version, description, dnx} = require('../package.json');
  const defaults = {
    color: '#ff0000',
    icon: 'gear',
  };

  let manifest = {
    name,
    version,
    description,
    ...defaults,
    ...dnx,
  };
  return manifest;
}

const manifest = createManifest();
export default manifest;

const bridge = {
  name: manifest.name,
  bootstrap: async props => {
    const {name, singleSpa} = props;
  },
  mount: async props => {
    const {interop} = props;
    interop?.register(bridge);
  },
  unmount: async props => {
    const {interop} = props;
    interop?.unregister(bridge);
  },
};

const core = {
  mount: async props => {
    acl.registerPermission('JourneyComposerParentChild', '8b5edad6-2974-4392-af96-bbe539ae397e');
    
    const {name, mountParcel, customProps} = props;
    for (const [key, value] in customProps) manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel(bridge, {domElement: document.body, interop: Window.interop});

    Window._shellReady.then(() => {
      document.dispatchEvent(
        new CustomEvent('dnx:app-loaded', {
          detail: {
            appmodule: name,
            config: {
              component: Overview,
              routes,
            },
          },
        })
      );
    });

    wire();
  },
};

// having anything more than a single awaitable screws with scheduling
// causing app-full to finish setup too early, triggering us being kicked to a 404 page for child pages
// bootstrap should thus be limited to just lifecycle.boostrap for now
export const bootstrap = [lifecycle.bootstrap];

// Mount is called once when active is truthy, after that application should handle route/hashchange/popstate
export const mount = [core.mount, lifecycle.mount];
export const unmount = [lifecycle.unmount];
export const update = [core.update || (() => Promise.resolve()), lifecycle.update];
// optional [unloadapplication is called]
export const unload = [lifecycle.unload];
