const types = {
    audience: 1,
    journey: 2,
    touchpoint: 3,
    audienceFilter: 5,
    abTest: 6,
    goal: 7,
};
export default types;
/** Does this brick type support adding brick comments */
export const supportsComments = [types.abTest, types.touchpoint, types.audienceFilter, types.goal];
/** Can this brick act as a touchpoint */
export const isTouchpointLike = [types.touchpoint, types.goal];
/** Order to display types in library/contextmenu  */
export const displayOrder = [types.audience, types.goal, types.abTest, types.journey, types.touchpoint];
/** Should this brick have route descriptions */
export const automaticRouteDescriptions = [types.abTest];
/** generator: v-for="(name,def) in inDisplayOrder(definitions)"  */
export function* inDisplayOrder(definitions) {
    for (const type of displayOrder) {
        for (const kvp of Object.entries(definitions).filter(([, value]) => value.type === type)) {
            yield kvp;
        }
    }
    for (const kvp of Object.entries(definitions).filter(([, value]) => !displayOrder.includes(value.type))) {
        yield kvp;
    }
}
