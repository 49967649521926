var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
/**
 * Controller for journeys as a whole.
 */
class Journey {
    constructor() {
        this.paths = Object.freeze({
            list: () => `api/journeys`,
            getById: (id, revisionid) => `api/journeys/${encodeURIComponent(id)}/${encodeURIComponent(revisionid)}`,
            create: () => `api/journeys`,
            update: () => `api/journeys`,
            upsertInternal: () => `api/internal/journeys`,
            delete: (id) => `api/journeys/${encodeURIComponent(id)}`,
            copy: (id, name) => {
                const __query = new QueryStringBuilder().add("name", name);
                return `api/journeys/${encodeURIComponent(id)}/clone${__query.toString()}`;
            },
            deactivate: (id, revisionid) => `api/journeys/${encodeURIComponent(id)}/${encodeURIComponent(revisionid)}/deactivate`,
            publish: (id) => `api/journeys/${encodeURIComponent(id)}/publish`,
            getTypes: () => `api/journeys/types`,
            getWorlds: () => `api/journeys/worlds`,
            getConsentPurposes: () => `api/journeys/consentpurposes`,
            getCampaigns: (type) => `api/journeys/campaigns/${encodeURIComponent(type)}`,
            getJourneyCampaigns: (journeyid, type) => `api/journeys/${encodeURIComponent(journeyid)}/campaigns/${encodeURIComponent(type)}`,
            getCustomFilterFields: () => `api/journeys/customfilterfields`,
            getCustomGoalFields: () => `api/journeys/customgoalfields`,
            getConditionDatasets: () => `api/journeys/conditiondatasets`,
            startSession: (journeyid, revisionid) => {
                const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
                return `api/journeys/startsession${__query.toString()}`;
            },
            terminateSession: (journeyid, revisionid) => {
                const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
                return `api/journeys/terminatesession${__query.toString()}`;
            },
            sessionHeartbeat: (journeyid, revisionid) => {
                const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
                return `api/journeys/sessionheartbeat${__query.toString()}`;
            },
            createChildJourneys: (id) => `api/journeys/${encodeURIComponent(id)}/createchildjourneys`,
            disconnectFromParent: (id) => `api/journeys/${encodeURIComponent(id)}/disconnectfromparent`,
        });
    }
    /**
     * Journey.List "GET api/journeys"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<JourneyListModel[]>>}
     */
    list(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetById "GET api/journeys/{id}/{revisionId}"
     * @param {Guid} id
     * @param {number} revisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<JourneyModel>>}
     */
    getById(id, revisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/${revisionid === undefined || revisionid === null ? "" : encodeURIComponent(revisionid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Create "POST api/journeys"
     * @param {JourneyModel} journeymodel
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    create(journeymodel, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, journeymodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Update "PUT api/journeys"
     * @param {JourneyModel} journeymodel
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    update(journeymodel, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, journeymodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.UpsertInternal "POST api/internal/journeys"
     * @param {JourneyModel} journeymodel
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    upsertInternal(journeymodel, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/internal/journeys`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, journeymodel);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Delete "DELETE api/journeys/{id}"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Copy "POST api/journeys/{id}/clone"
     *
     * Creates an exact (deep) copy of the entire journey structure and saves it to the database with new GUIDs.
     * @param {Guid} id The journey id
     * @param {JourneyModel} model The model for the journey to be cloned
     * @param {string} name Name of the target cloned journey
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<JourneyModel>>}
     */
    copy(id, model, name, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("name", name);
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/clone${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Deactivate "PATCH api/journeys/{id}/{revisionId}/deactivate"
     *
     * Deactivate the given journey revision. Journey deactivation is idempotent.
     * @param {Guid} id JourneyId
     * @param {number} revisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Operation result
     */
    deactivate(id, revisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/${revisionid === undefined || revisionid === null ? "" : encodeURIComponent(revisionid)}/deactivate`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.Publish "PATCH api/journeys/{id}/publish"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    publish(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/publish`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetTypes "GET api/journeys/types"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<JourneyTypeModel[]>>}
     */
    getTypes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/types`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetWorlds "GET api/journeys/worlds"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WorldModel[]>>}
     */
    getWorlds(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/worlds`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetConsentPurposes "GET api/journeys/consentpurposes"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ConsentPurposeModel[]>>}
     */
    getConsentPurposes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/consentpurposes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetCampaigns "GET api/journeys/campaigns/{type}"
     * @param {string} type
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<Object[]>>}
     */
    getCampaigns(type, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/campaigns/${type === undefined || type === null ? "" : encodeURIComponent(type)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetJourneyCampaigns "GET api/journeys/{journeyId}/campaigns/{type}"
     * @param {Guid} journeyid
     * @param {string} type
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<Object[]>>}
     */
    getJourneyCampaigns(journeyid, type, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${journeyid === undefined || journeyid === null ? "" : encodeURIComponent(journeyid)}/campaigns/${type === undefined || type === null ? "" : encodeURIComponent(type)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetCustomFilterFields "GET api/journeys/customfilterfields"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomFilterFieldModel[]>>}
     */
    getCustomFilterFields(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/customfilterfields`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetCustomGoalFields "GET api/journeys/customgoalfields"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CustomGoalFieldModel[]>>}
     */
    getCustomGoalFields(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/customgoalfields`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.GetConditionDatasets "GET api/journeys/conditiondatasets"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ConditionDatasetFieldModel[]>>}
     */
    getConditionDatasets(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/conditiondatasets`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.StartSession "POST api/journeys/startsession"
     * @param {Guid} journeyid
     * @param {number} revisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    startSession(journeyid, revisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
            let path = `api/journeys/startsession${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.TerminateSession "POST api/journeys/terminatesession"
     * @param {Guid} journeyid
     * @param {number} revisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    terminateSession(journeyid, revisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
            let path = `api/journeys/terminatesession${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.SessionHeartbeat "POST api/journeys/sessionheartbeat"
     * @param {Guid} journeyid
     * @param {number} revisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<AppSessionModel>>}
     */
    sessionHeartbeat(journeyid, revisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("journeyId", journeyid).add("revisionId", revisionid);
            let path = `api/journeys/sessionheartbeat${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.CreateChildJourneys "GET api/journeys/{id}/createchildjourneys"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    createChildJourneys(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/createchildjourneys`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Journey.DisconnectFromParent "GET api/journeys/{id}/disconnectfromparent"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    disconnectFromParent(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/journeys/${id === undefined || id === null ? "" : encodeURIComponent(id)}/disconnectfromparent`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const journey = new Journey();
export { journey as default, Journey };
