import {useNotifications, usePopupService, T} from '@dnx/core';
import {shallowRef, resolveComponent, h} from 'vue';

import {useJourneyList} from './composables';
import ShareJourney from './components/ShareJourneyV2.vue';
import {useJourneyStore} from './store';

/** Initialize journey share dialog */
export function share(journey) {
  const service = usePopupService();
  const {loadJourneys} = useJourneyList();

  // all sharing behavior/logic is contained in the ShareJourney component...
  service.show({
    title: `${T('JOURNEY_COMPOSER_SHARE')} ${journey.name}`,
    foreground: true,
    body: h(ShareJourney, {
      journey: journey,
      onShare: () => loadJourneys(),
    }),
  });
}

/** Initialize journey clone dialog */
export function clone(journey) {
  const service = usePopupService();
  const journeyStore = useJourneyStore();
  const {successNotification, errorNotification} = useNotifications();
  const name = shallowRef(`${journey.name} - ${T('JOURNEY_COPY')}`);
  const {loadJourneys} = useJourneyList();

  const popup = service.show({
    title: T('JOURNEY_DUPLICATE_TITLE'),
    foreground: true,

    body: {
      // resolveComponent can only be invoked in setup/render functions
      render() {
        return h(resolveComponent('XInput'), {
          placeholder: T('JOURNEY_DUPLICATE_NAME'),
          autoSelect: true,
          modelValue: name.value,
          ['onUpdate:modelValue']: value => (name.value = value),
        });
      },
    },

    buttons: [
      {
        name: 'cancel',
        label: T('CANCEL'),
        style: 'white',
        handler: () => popup.close(),
      },
      {
        name: 'save',
        label: T('DUPLICATE_JOURNEY_BUTTON'),
        style: 'primary-success',
        icon: {name: 'check-circle'},
        handler: () =>
          journeyStore
            .duplicateJourney(journey.id, name)
            .then(() => {
              successNotification(T('JOURNEY_DUPLICATE_SUCCESS'));
              loadJourneys().then(() => {}); // seems like it should be part of our store..
            })
            .catch(() => errorNotification(T('ERROR_NONE')))
            .finally(() => popup.close()), // <-- should this be handled by the popup itself?
      },
    ],
  });
}
