import {Uuid} from '@dnx/core';
import {cleanKey, isEmpty, hydrate} from '../util';

export class BrickDefinition {
  constructor(options = {}) {
    // Inject config options in the definition
    hydrate(options);

    const defaults = {
      id: undefined,
      type: 0,
      color: undefined,
      icon: undefined,
      visible: true,
      displayName: undefined,

      typeName: 'unknown',
      description: '',

      initializer: [],
      validator: [],
      hide: [],
      features: {},
      fields: undefined,
    };
    Object.assign(this, {...defaults, ...options});

    // Db serves up null as content, but should be undefined
    if (this.color === null) this.color = undefined;
    if (this.icon === null) this.icon = undefined;

    if (this.id == undefined) throw new Error('Definition id is required');
  }
  /** Add a feature with conditional support. This allows for a dynamic ui.
   * * <example>def.addFeature('campaign')</example>
   */
  addFeature(name, condition = undefined) {
    this.features[name] = condition;
  }

  /** See if the definition supports a feature
   * A missing feature is always supported.
   * <example>brick.def().supports('campaign')</example>
   */
  supports(feature, ...options) {
    try {
      const name = cleanKey(feature);
      // Unknown feature => allow if nothing else is set
      if (!Object.hasOwn(this.features, name)) return isEmpty(this.features);

      const condition = this.features[name];

      // Allow if condition says so.
      if (typeof condition === 'function') return condition(...options);

      // Allow if Condition not set or truthy
      if (isEmpty(condition) || !!condition) return true;
    } catch (e) {
      console.log(`Condition ${this.displayName || this.typeName}(${feature}) failed`, e);
    }

    return false;
  }

  // Add options for field
  addOptions(name, options) {
    if (!Array.isArray(options) && typeof options !== 'function')
      throw new Error('Options should be an array or a function.');

    this.hide.push(`${cleanKey(name)}-options`);
    this[`${cleanKey(name)}-options`] = options;
  }

  // Return options (foo-options) for a field (foo). Foo-options can be a function
  getOptions(instance, name) {
    const options = this[`${cleanKey(name)}-options`];
    if (typeof options === 'function') {
      // eslint-disable-next-line prefer-rest-params
      return options.apply(instance, arguments);
    }

    return options;
  }

  validate(instance) {
    const issues = [];
    for (const check of Object.values(this.validator)) {
      if (typeof check === 'function') {
        const issue = check(instance);
        if (isEmpty(issue)) {
          continue;
        }
        if (Array.isArray(issue)) {
          issues.push(...issue);
        } else {
          issues.push(issue);
        }
      }
    }
    return issues;
  }
}

// Mimic static, since babel fails on this.
BrickDefinition.Empty = new BrickDefinition({id: new Uuid()});
