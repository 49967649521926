// The brick factory can create new bricks based on the definitions
import definitions from './definitions';

import {isEmpty} from '../util';
import {Types as checkpointTypes, Definitions as checkpointDefinitions} from '../types/checkpoint-definition';

class BrickFactory {
  create(name, config) {
    if (typeof name !== 'string' || name?.length < 1) throw new Error(`Name is required. Got: ${name}`);

    // Clone definition, remove id, add model
    const brick = definitions.create(name, config);

    if (Array.isArray(brick.initializer)) brick.initializer.forEach(fn => fn(brick));

    return brick;
  }

  parse(model) {
    const brick = this.create(definitions.nameFromType(model.type), model);
    try {
      const map = {};
      for (const connection of model.connections) {
        const checkpoints = {};

        Object.values(checkpointTypes).forEach(checkpointType => {
          let checkpointModel = connection.config[checkpointType];
          if (checkpointType === checkpointTypes.exclusionjourney && isEmpty(checkpointModel)) {
            // Config key journey was renamed to exclusionJourneyCheckpoint [20221010]
            checkpointModel = connection.config['journey'];
          }
          if (isEmpty(checkpointModel)) return;

          checkpoints[checkpointType] = checkpointDefinitions[checkpointType].create(checkpointModel);
        });

        connection.checkpoints = checkpoints;

        if (connection.sourceBrickId == brick.id) brick.outgoing[connection.id] = connection;
        if (connection.destinationBrickId == brick.id) brick.incoming[connection.id] = connection;
        map[connection.id] = connection;
      }
      brick.connections = map;
    } catch (e) {
      console.error(`Could not parse model: ${e}`);
    }
    return brick;
  }

  /** Validate this model(brick) against the definitions */
  validate(model) {
    return definitions.get(model.typeName)?.validate(model);
  }

  async load() {
    await definitions.load();
  }

  get definition() {
    return definitions;
  }
}

const factory = new BrickFactory();

export default factory;
